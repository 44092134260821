.kinto-service-selector{
    .blocks{
        .hero-full{
            .title1 {
                margin-bottom: 3rem;
            }
            @include sm{
                height: unset;
                .container{
                    display: flex;
                    flex-direction: column;
                    .service-selector{
                        padding: 30px 0px 100px 0px;
                        .card{
                            .new-notification{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #9747FF;
                                padding: 10px;
                                border-radius: 8px;
                                position: absolute;
                                top: 25px;
                                right: 30px;
                                small{
                                    font-family: 'ToyotaType', sans-serif;
                                    font-weight: 700;
                                    @include font-size(11);
                                    line-height: 9px;
                                    text-align: center;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}