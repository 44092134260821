.experiencia-kinto {
    position: relative;
    min-height: 20rem;
    overflow: hidden;


    &__back {
        position: absolute;
        z-index: -1;
        top: 0; bottom: 0;
        right: 50vw;
        height: 100%;
    }

    &__slider {
        margin-top: 2rem;
        margin-bottom: 2rem;

        &-slide {
            width: 100%;
            height: 100%;
            display: grid !important;
            grid-template-columns: 1.2fr .8fr;
            align-items: center;

            &__img {
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    border-radius: 8px;
                    height: 460px
                }
            }

            &__info {
                padding: 2rem 0 2rem 2rem;

                .copy {
                    margin-bottom: 2rem;
                    p {
                        margin-bottom: 0.8rem;
                    }
                }

                .button-arrow {
                    margin-top: 3rem;
                }
            }
        }
    }

    .gallery__bottom {
        display: inline-block;
        margin-left: calc(50% + 1rem);
    }

    @include md {
        &__back {
            right: 0; left: 0;
            bottom: 70%;
            width: 100%;
            top: auto;
        }

        &__slider {
            .slick-list {
                margin: 0 1.5rem;
            }

            &-slide {
                grid-template-columns: 1fr;
                grid-template-rows: auto;

                &__info {
                    padding: 2rem 0 0;
                }
            }
        }

        .gallery__bottom {
            display: block;
            margin-left: 0;
        }
    }
}
