/* ~~~~~~~~~~~~~~~~~~~~ */
/* VEHICLES GALLERY */
/* ~~~~~~~~~~~~~~~~~~~~ */

.vehicles-gallery {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    .models-slider {
       &--single{
           display: none;
       }
    }

    &__title-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2{
            margin-bottom: 0;
        }
        a{
            font-size: 18px;
            text-decoration: underline;
        }
    }
}

.vehicles-gallery .slick-list {
    // overflow: visible;
    margin: 0px 34px;
}


// @media (max-width: 767px) {
//     .vehicles-gallery .slick-list {
//         padding-left: 0.5rem !important;
//     }
// }

.vehicles-gallery__list {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.vehicles-gallery__item {
    width: 47%;
    max-width: 348px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    position: relative;
    transition: all 0.4s ease-in-out;

    &:hover {
        .vehicles-gallery__title {
            background: $pale-blue; /* Old browsers */
            background: -moz-linear-gradient(-45deg,  rgba($pale-blue,.3) 0%, rgba($pale-blue,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg,  rgba($pale-blue,.3) 0%,rgba($pale-blue,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg,  rgba($pale-blue,.3) 0%,rgba($pale-blue,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        .vehicles-gallery__info {
            .button {
                color: $navy;
                background-color: $pale-grey;
                border-color: $pale-grey;
            }
        }
    }
}

    .vehicles-gallery__link {
        text-decoration: none;
        color: inherit;
        outline: none;
        display: block;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }

        .vehicles-gallery__title {
            box-sizing: border-box;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 1rem;
            padding-bottom: 3rem;
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.35;
            transition: all 0.4s ease-in-out;

            min-height: 7.5rem;
            border-radius: 6px;
        }

            .vehicles-gallery__title strong {
                font-weight: 600;
            }

        .vehicles-gallery__title--claim {
            opacity: 1;
        }

        .vehicles-gallery__image {
            position: relative;
            top: -50px;
            z-index: 1;
            max-width: 85%;
            height: 130px;
            transition: transform 0.4s ease-in-out;
        }

        .vehicles-gallery__info {
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            margin-top: -40px;

            &-name {
                @include font-size(25);
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.28;
                letter-spacing: normal;
                margin-bottom: 15px;
            }
            &-copy {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__info{
                    @include font-size(18);
                    font-weight: 500;
                    min-height: 48px;
                    p {
                        font-weight: normal;
                        small {
                            font-size: 0.5rem;
                        }
                    }
                }
                button{
                    @include font-size(13);
                    font-family: 'ToyotaType';
                    font-weight: 600;
                    line-height: 23px;
                }
            }
            &-small {
                @include font-size(10);
                color: $steel-grey;
            }
            .button-container{
                display: flex;
                justify-content: end;
                margin-top: 20px
            }
            .button {
                display: block;
                margin-left: auto;
                margin-top: 10px;
            }
        }

@media (max-width: 577px) {
    .vehicles-gallery {
    &__list {
        display: none;
    }

    .models-slider {
        &--single{
            display: block;
            width: 100%;
            padding-top: 15px;
            &__list-item{
                padding: 0;
            }
            margin-bottom: 50px;
            .button{
                width: 130px;
            }
            .row{
                .models-slider--single__list-item-data__action{
                    align-items: center;
                }
                .title3{
                    margin-bottom: 10px;
                }
            }
        }
    }

}
    .vehicles-gallery .slick-list {
        margin: 0;
    }

    .vehicles-gallery__item {
        margin-top: .5rem;
        margin-bottom: .5rem;

        .vehicles-gallery__title {
            background: $pale-blue; /* Old browsers */
                    background: -moz-linear-gradient(-45deg,  rgba($pale-blue,.3) 0%, rgba($pale-blue,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(-45deg,  rgba($pale-blue,.3) 0%,rgba($pale-blue,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(135deg,  rgba($pale-blue,.3) 0%,rgba($pale-blue,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

        .vehicles-gallery__info {
            .button {
                margin-right: auto;
                background-color: $pale-grey;
                border-color: $pale-grey;
            }
        }
    }
}

@include sm-min {
    .vehicles-gallery.one_personal {
        .vehicles-gallery__item {
            display: flex;
            flex-direction: column;
        }
        .vehicles-gallery__info{
            .vehicles-gallery__link{
                height: unset;
            }
        }
    }
}

@include md-min {
    .vehicles-gallery.one_personal {
        .vehicles-gallery__item {
            display: flex;
            flex-direction: column;

            .vehicles-gallery__info {
                .button {
                    display: inline-block;
                    margin-left: 110px;
                    margin-top: -30px;
                }
            }
        }
        .vehicles-gallery__info{
            .vehicles-gallery__link{
                height: 100%;
            }
        }
    }
}
        
