.sect_comercial-info{
    .comercial-info{
        padding-bottom: 30px;
        padding-top: 40px;

        &__list {
            padding-bottom: 250px;
        }

        &__title {
            margin-bottom: 25px;
        }
    }
}