.experiencias-grid {
    list-style: none;
    padding: 0;
    margin: 0 0 4rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;

    &__item {
        border-radius: 6px;
        padding: 1rem;
        transition: box-shadow 0.3s ease-in-out;
        display: grid;

        &:hover {
            box-shadow:
                0 0px 25px 2px rgba(0, 0, 0, 0.12), 
                0 0px 14px -4px rgba(0, 0, 0, 0.017);
            ;
        }

        &-img {
            display: block;
            max-width: 100%;
            height: auto;
            border-radius: 6px;
            margin-bottom: .5rem;
        }

        &-copy {
            font-size: .5rem;
            line-height: 1.2;
            margin-bottom: 1rem;

            p {
                margin-bottom: .25rem;
            }
        }

        &-data {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: .5rem;

            &__price {
                strong {
                    @include font-size(20);
                    font-weight: 300;
                }
                p {
                    @include font-size(10);
                    color: $steel-grey;
                }
            }
            &__more {
                text-align: right;
            }
        }
    }

    @include rwd(1170) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include rwd(900) {
        grid-template-columns: 1fr 1fr;
    }

    @include sm {
        grid-template-columns: 1fr;
        grid-row-gap: 2.5rem;
        margin-top: 1.5rem;

        &__item {
            padding: 0;

            &:hover {
                box-shadow: none;
            }
        }
    }
}