.models-slider--single {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0 0 1.5rem;

        .row {
            display: grid;
            grid-template-columns: .25fr .75fr;
            grid-template-rows: 1fr 1fr;
            grid-column-gap: 2rem;
            grid-template-areas:    "copy pic"
                                    "action pic";
            align-items: center;

            @include md {
                display: block;
            }
        }

        &-item {
            padding: 0 .5rem;

            &-data__copy {
                grid-area: copy;
                margin-top: 10px;
                .title2 {
                    margin-bottom: .7rem;
                }
                .title3 {
                    margin-bottom: .7rem;
                }
                p {
                    margin-bottom: 1rem;
                    height: 50px;
                }
            }

            &-data__action {
                grid-area: action;
                &-price {
                    font-weight: normal;

                    small {
                        @include font-size(14);
                    }
                }

                &-franchise {
                    display: block;
                    @include font-size(10);
                    color: $steel-grey;
                    margin: .5rem 0 1.5rem;
                }

                &.one-personal {
                    flex-direction: column;
                    align-items: flex-start !important;

                    a {
                        align-self: flex-end;
                        margin-top: 1rem;
                    }
                }
            }

            &-pic {
                grid-area: pic;
                position: relative;

                &__back {
                    position: absolute;
                    top: 0; right: 0;
                    z-index: -1;
                    width: 80%;
                    height: 70%;
                    border-radius: 6px;
                    background: $pale-blue; /* Old browsers */
                    background: -moz-linear-gradient(-45deg,  rgba($pale-blue,.1) 0%, rgba($pale-blue,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(-45deg,  rgba($pale-blue,.1) 0%,rgba($pale-blue,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(135deg,  rgba($pale-blue,.1) 0%,rgba($pale-blue,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

                }

                &__img {
                    display: block;
                    max-width: 100%;
                    max-height: 330px;
                    height: auto;
                    margin: 3rem auto 0;

                }
            }

            &-offer-tag {
              padding: 4px 8px;
              max-width: fit-content;
              border-radius: 4px;
              font-size: 10px;
              line-height: 14px;
              font-weight: 600;
              color: #FFFFFF;
            }

            .models-slider__disclaimer-text {
              color: #738184;
              font-size: 10px;
              line-height: 15px;
              font-weight: 500;
              margin-top: 20px;
            }

            @include md {
                &-pic {
                    height: 250px;
                    &__back {                    
                        height: auto;
                        top: -100px;
                        bottom: 30%;
                        width: 100%;
                    }
                    &__img {
                        margin-top: 2rem;
                    }
                }

                &-data__action {
                    @include flexbox(row, nowrap, space-between, end);

                    &-franchise {
                        margin-bottom: 0;
                    }
                }
            }

            @include sm{
                &-pic {
                    height: 140px;
                }
            }
        }
    }
}