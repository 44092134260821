.form {
    &__wrapper {
        &:last-of-type {
            margin-bottom: 2.25rem;
        }

        .cols {
            @include sm {
                display: initial;
            }
        }

        .col {
            width: 100%;
            flex: 1 1 auto;

            @include md-min {
                margin-left: 1rem;
                margin-right: 1rem;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            .form__input-wrapper {
                margin-bottom: 0;
            }
        }
    }

    &__input-aside {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 22px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    }

    &__input-wrapper {
        position: relative;
        margin-top: 2rem;
        margin-bottom: 1rem;

        &:first-child {
            margin-top: 1rem;
        }

        &--select {
            .form{
                &__input {
                    appearance: none;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.4;
                }

                &__input-helper {
                    position: relative;

                    &:after {
                        content: '';
                        pointer-events: none;
                        position: absolute;
                        top: calc(50% - ((1rem / 3) / 2));
                        right: (1rem / 3);
                        display: block;
                        height: (1rem / 3);
                        width: (1rem / 3);
                        border-bottom: 2px solid;
                        border-right: 2px solid;
                        transform: rotate(45deg) translateY(-50%);
                    }
                }
            }
        }

        &--radio {
            .form {
                &__input {
                    width: initial;
                    vertical-align: middle;
                    margin: 0;
                    margin-right: .125rem;
                }

                &__label {
                    position: initial;
                    vertical-align: middle;
                }
            }
        }
    }

    &__label {
        position: absolute;
        bottom: .5rem;
        left: 0;
        transition: all .4s ease-in-out;
        color: $ocean;
        font-size: .7rem;
        line-height: 1.2rem;
        font-weight: 500;
        pointer-events: none;
        &.textarea{
            bottom: unset;
            top: 0px;
        }

        &-error {
            color: #ec0000;
            display: none;
            font-size: x-small;
            font-weight: 900;
            margin-top: -5px;
        }
        &--hidden{
            display: none;
        }
    }

    &__input {
        height: 1.5rem;
        &:active,
        &:focus,
        &.-interacted,
        &[type="date"] {
            & + .form__label {
                bottom: 1.5rem;
                &.textarea{
                    top: -20px;
                    bottom: unset;
                }
            }
            & + label + .form__label {
                bottom: 1.5rem;
            }
        }
    }

    &__input, &__textarea {
        width: 100%;
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $steel-grey;

        font-size: .8rem;
        line-height: 1.2rem;
        font-weight: 500;


    }

    &__textarea {
        resize: none;
        margin-top: .6em;
        &:active,
        &:focus,
        &.-interacted,
        &[type="date"] {
            & + .form__label {
                bottom: 5.2rem;
            }
            & + label + .form__label {
                bottom: 5.2rem;
            }
        }
    }

    &__submit {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include sm {
            margin-top: 1.5rem;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.2;
        margin-top: 2.25rem;
        margin-bottom: 2rem;

        & + .cols {
            margin-top: -2rem;
        }
    }

    &__row {
        @include flexbox(row, wrap, space-between, center);
        margin-top: 2rem;

        > * {
            margin-bottom: 1rem;
        }

        @include sm {
            display: block;

            > * {
                margin-bottom: 0;
            }

            &-submit {
                text-align: center;
            }
        }

    }

    &--contact {
        margin-bottom: 3.5rem;
    }

    &__checkbox {
        input[type=checkbox] {
            display: none;
            &:checked + label::after {
                display: block;
            }
        }
        label {
            display: flex;
            align-items: center;
            font-size: 0.7rem;
            line-height: 1.2rem;
            font-weight: 500;
            position: relative;
            cursor: pointer;

            &::before {
                content: "";
                width: 24px;
                height: 24px;
                display: inline-block;
                border: 1px solid $navy;
                border-radius: 3px;
                margin-right: 22px;
            }
            &::after {
                content: "";
                display: none;
                height: 13px;
                width: 5px;
                left: 10px;
                top: 4px;
                border-right: 1px solid $navy;
                border-bottom: 1px solid $navy;
                position: absolute;
                transform: rotate(45deg);
            }
        }
        @include md {
            margin-bottom: 1rem;
        }
    }

    &__disclaimer {
        display: block;
        margin-top: 2rem;
        @include font-size(10);
    }
}