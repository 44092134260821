.sevice-detail-container{
    margin-top: 100px;
    margin-bottom: 100px;
    padding-right: 0px;
    display: flex;
    flex-wrap: wrap;
    // min-height: 650px;
    position: relative;
    .left-section{
        margin-left: 20%;
        h2{
            @include font-size(50);
            font-family: 'ToyotaType';
            font-weight: 400;
            line-height: 55px;
            color: $ocean;
            max-width: 475px;
            margin-bottom: 40px;

            &.small-title {
              font-size: 1.5rem;
              font-weight: bold;
              margin-bottom: 20px;
              line-height: 40px;
            }
        }
        .details-list{
            ul{
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                li{
                    display: flex;
                    .check{
                        background:  url('../img/icons/check.svg') no-repeat center;
                        height: 30px;
                        padding-top: 7px;
                        padding-left: 25px;
                    }
                    p{
                        font-size: 0.9rem;
                        margin-left: 10px;
                        strong{
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
    .right-section{
        position: absolute;
        right: -150px;
        top: 100px;
    }

    @include sm{
        flex-direction: column;
        // min-height: 650px;
        position: initial;
        padding-right: 1rem;
        box-sizing: border-box;
        width: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        .left-section{
            margin-left: 0;
            h2{
                @include font-size(30);
                line-height: 30px;
            }
        }
        .right-section{
            position: initial;
            left: 0px;
            margin-top: 40px;
            .image-container{
                img{
                    width: 100%;
                    position: relative;
                    left: 30px;
                }
            }
        }
    }
}