.title1 {
    @include font-size(50);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.7px;
    color: $ocean;
    margin-bottom: 2.5rem;

    @include sm {
        @include font-size(30);
        line-height: 1.17;
        letter-spacing: normal;
        margin-bottom: 1rem;
    }
}

.title2 {
    @include font-size(40);
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -0.7px;
    color: $navy;
    margin-bottom: 1.5rem;

    @include sm {
        @include font-size(30);
        line-height: 1.17;
        letter-spacing: normal;
        margin-bottom: .7rem;
    }
}

.title3 {
    @include font-size(30);
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 2rem;

    @include sm {
        @include font-size(25);
    }
}

.title4 {
    @include font-size(20);
    font-weight: 600;
    line-height: 1.35;
    color: $navy;
    margin-bottom: .55rem;
}

.subhead {
    display: block;
    color: $ocean;
    margin-bottom: 1rem;
    @include font-size(16);
}