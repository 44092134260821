.sect_modelos {
    .title1 {
        max-width: 960px;
        margin: 0 auto 5rem;
        line-height: 1.2em;
    }
    $stop: 51%;
    .extra-background {
        background: $pale-grey;
        background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) $stop, rgba($pale-grey,1) $stop, rgba($pale-blue,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) $stop, rgba($pale-grey,1) $stop, rgba($pale-blue,1) 100%);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) $stop, rgba($pale-grey,1) $stop, rgba($pale-blue,1) 100%);

        @media (max-width: $screen-md) {
            background: $pale-grey;
            background: -moz-linear-gradient(180deg, rgba($pale-grey,1) 0%, rgba($pale-blue,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba($pale-grey,1) 0%, rgba($pale-blue,1) 100%);
            background: linear-gradient(180deg, rgba($pale-grey,1) 0%, rgba($pale-blue,1) 100%);
        }
    }
}