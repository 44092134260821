.footer {
    $root: &;
    flex-shrink: 0;
    background-color: $navy;
    padding: 3rem 0;
    @include font-size(14);
    line-height: 1.86;


    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "info nav";
        grid-column-gap: 1.5rem;
    }

    &__info {
        grid-area: info;
        color: $pale-blue;
        font-weight: 300;
        display: flex;
        flex-direction: column;

        &__top {
            margin-bottom: 20px;
            .footer__logo {
                margin-bottom: 1rem;
            }
        }

        &__help {
            margin-bottom: 40px;

            h6 {
                @include font-size(20);
                font-weight: 700;
            }
        }

        a {
            @include font-size(14);
            color: $aqua-blue;
            &:hover {
                text-decoration: none;
            }
    
            &.phone {
                color: $white;
            }
        }
    }

    &__nav {
        grid-area: nav;
        text-align: right;
        a {
            color: $white;
        }
        
        &__socialmedia {
            color: #EFEEEF;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            ul {
                display: flex;
                justify-content: end;
                gap: 1rem;
    
                .social-media-btn {
                    background-color: $white;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    color: inherit;
                    transition: all .2s ease-in-out;
    
                    img {
                        width: 24px;
                        aspect-ratio: 1 / 1;
                        filter: invert(1);
                    }
                }
            }
        }

        &__menu {
            margin-top: 44px;
        }
    }

    @include md {
        &__wrapper {
            display: block;

        }
        &__info {
            margin-bottom: 1.5rem;
        }

        &__nav {
            text-align: left;
            &__socialmedia {
                ul {
                    justify-content: flex-start;
                }
            }
        }
    }
}