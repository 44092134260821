.partners {
    margin-bottom: 100px;

    &__title {
        @include font-size(50);
        color: $ocean;
        margin-bottom: 50px;

        @include sm { 
            @include font-size(30);
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sm { 
            row-gap: 14px;
        }

        &__item {
            flex: 1 0 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
                max-width: 188px;
            }

            @include sm { 
                width: 165px;
            }
        }
    }

    @include sm { 
        margin-bottom: 80px;
    }
}