.models-services-selector{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 50px;
    &__list{
        display: flex;
        justify-content: center;
        column-gap: 90px;
        align-items: center;
    }
    &__item{
        text-align: center;
        height: 40px;
        width: 180px;
        font-family: 'ToyotaType';
        font-weight: 600;
        color: $ocean;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 4px solid #00000000;
        transition: all 200ms ease-out;
        &:hover{
            color:$navy;
            border-bottom: 4px solid $navy;
            transition: all 200ms ease-out;
        }
        &.selected{
            color:$navy;
            border-bottom: 4px solid $navy;
            transition: all 200ms ease-out;
        }
    }
    @include sm {
        justify-content: start;
        overflow-x: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */  
        &::-webkit-scrollbar {
          display: none;
        }
        &__list{
            column-gap: 0px;
        };
    }
}
.share-section, .one-personal-section, .one-business-section{
    display: none;
    padding-bottom: 100px;
    &.show{
        display: grid;
    }
    
    .disclaimer {
        line-height: .6;
        margin-top: 20px;
        margin-bottom: 20px;
        
        small {
            color: $steel-grey;
            font-size: .5rem;
        }
    }
}
.models-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row: auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 4rem;
    width: 100%;

    &__item {
        width: 100%;
        padding-top: 20px;
        &-offer-tag {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 100;
          padding: 2px 10px 2px 20px;
          font-size: 0.5rem;
          color: white;
          line-height: 14px;

          &:before {
            content: '';
            position: absolute;
            left    : 0;
            bottom  : 0;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent; 
            border-left: 9px solid white;
          }
        }

        &-img {
            display: block;
            max-width: 100%;
            height: 130px;
            margin: 0 auto;
            margin-bottom: .5rem;
        }

        &-small {
            display: block;
            @include font-size(10);
            color: $steel-grey;
        }

        &-data {
            margin-top: 1rem;
            
            &__title {
                @include font-size(20);
                font-weight: 600;
                margin-bottom: .5rem;
            }
            &__price {
                @include font-size(14);
            }
            &__buy {
                text-align: right;
                &.one_personal {
                    margin-top: 20px;
                }
                a{
                    padding: 3px 30px;
                }
            }

            .row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: .5rem;
                &.one-personal-info{
                    display: block;
                }
            }
        }
    }

    @include lg {
        grid-template-columns: repeat(3, 1fr);
    }

    @include md {
        grid-template-columns: 1fr 1fr;
    }

    @include sm {
        grid-template-columns: 1fr;
    }
}