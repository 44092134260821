/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  /* makes sure it stays on top */
}

#status {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#status {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

#status div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00708d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

#status div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

#status div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

#status div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

#status div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
:root {
  --vh: 1vh;
}

/*
	Reset based on "Eric Meyer Reset 2.0"
*/
html, body {
  width: 100%;
  height: 100%;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea, input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

html, body {
  width: 100%;
  height: 100%;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, textarea,
input:focus, textarea:focus {
  outline: none;
  -webkit-appearance: none;
}

a {
  color: inherit;
}

* {
  position: relative;
}

/* Allow only vertical resizing of textarea */
textarea {
  resize: vertical;
}

/* apply a natural box layout model to all elements, but allowing components to change */
*, *:after, *:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* MIXINS: @include transition(); */
.-align-left {
  text-align: left !important;
  justify-content: flex-start !important;
}

.-align-right {
  text-align: right !important;
  justify-content: flex-end !important;
}

.-align-center {
  text-align: center !important;
  justify-content: center !important;
}

.-center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.-hide {
  display: none;
}

.-header-offset {
  padding-top: 7rem;
}

.-block-top {
  padding-top: 5rem;
}

.-block-bottom {
  padding-bottom: 5rem;
}

.-bg-navy {
  background-color: #002732;
  color: #fff;
}

.-bg-ocean {
  background-color: #00708d;
  color: #fff;
}

.-bg-pale-blue {
  background-color: #e3e9eb;
}

@media (max-width: 769px) {
  .-block-top {
    padding-top: 4rem;
  }
  .-block-bottom {
    padding-bottom: 4rem;
  }
  .-md-align-center {
    text-align: center !important;
    justify-content: center !important;
  }
  .-header-offset {
    padding-top: 6rem;
  }
}
@media (max-width: 577px) {
  .-block-top {
    padding-top: 3rem;
  }
  .-block-bottom {
    padding-bottom: 3rem;
  }
  .-header-offset {
    padding-top: 5rem;
  }
}
.-fs-14 {
  font-size: 14px;
  font-size: 0.7rem;
  line-height: 1.5;
}
.-fs-14 h4 {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 700;
}

@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Light.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Light.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Light.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Light.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Light.svg#ToyotaType-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Regular.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Regular.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Regular.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Regular.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Regular.svg#ToyotaType-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Book.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Book.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Book.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Book.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Book.svg#ToyotaType-Book") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "ToyotaType";
  src: url("../fonts/ToyotaType/ToyotaType-Semibold.eot");
  src: url("../fonts/ToyotaType/ToyotaType-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ToyotaType/ToyotaType-Semibold.woff2") format("woff2"), url("../fonts/ToyotaType/ToyotaType-Semibold.woff") format("woff"), url("../fonts/ToyotaType/ToyotaType-Semibold.ttf") format("truetype"), url("../fonts/ToyotaType/ToyotaType-Semibold.svg#ToyotaType-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("../fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  min-width: 320px;
  font-family: "ToyotaType", sans-serif;
  line-height: 1.35;
  font-weight: 500;
  color: #002732;
  overflow: initial;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
body .site-wrapper {
  flex: 1 0 auto;
  overflow-x: hidden;
}
body.modal-opened {
  overflow: hidden;
}

strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #00708d;
  transition: color 0.3s ease;
}
a:hover {
  color: #002732;
  text-decoration: underline;
}

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.separator {
  display: block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  border: none;
  border-top: 1px solid #d4d7d9;
}
.separator--top {
  margin-top: 2rem;
}

.slick-slider:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}
.slick-slider .slick-arrow {
  font-size: 0;
  padding: 0;
  border: none;
  background: none;
  width: 1rem;
  height: 1rem;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 2rem;
  background-color: rgba(255, 255, 255, 0.75);
  transition: visibility 0s, opacity 0.5s linear;
}
.slick-slider .slick-arrow:after {
  content: "";
  display: block;
  max-height: 1rem;
  max-width: 1rem;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../img/icons/chevron.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .slick-slider .slick-arrow {
    visibility: hidden;
    opacity: 0;
  }
}
@media (max-width: 577px) {
  .slick-slider .slick-arrow {
    display: none !important;
  }
}
.slick-slider .slick-arrow.slick-prev {
  left: 0rem;
}
.slick-slider .slick-arrow.slick-prev:after {
  transform: rotate(90deg);
}
.slick-slider .slick-arrow.slick-next {
  right: 0rem;
}
.slick-slider .slick-arrow.slick-next:after {
  transform: rotate(-90deg);
}
.slick-slider .slick-arrow.slick-disabled {
  display: none !important;
}

.slick-dots {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-dots [role=presentation] {
  line-height: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.slick-dots [role=presentation]:first-of-type {
  margin-left: 0;
}
.slick-dots [role=presentation]:last-of-type {
  margin-right: 0;
}
.slick-dots [role=presentation].slick-active [role=tab] {
  width: 2rem;
  background-color: #a8bac1;
}
.slick-dots [role=tab] {
  cursor: pointer;
  font-size: 0;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 100px;
  background-color: #e3e9eb;
  height: 0.5rem;
  width: 0.5rem;
  outline: none;
  transition: all 0.4s ease-in-out;
}

.button {
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  font-size: 0.65rem;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 100px;
  border: 2px solid;
  outline: none;
  transition: all 0.4s ease-in-out;
}
.button:hover {
  text-decoration: none;
}
@media (max-width: 577px) {
  .button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.button--small {
  min-width: 7.5rem;
}
.button--big {
  min-width: 10rem;
}
.button--white {
  color: #002732;
  background-color: #fff;
  border-color: #fff;
}
.button--white:hover {
  color: #fff;
  background-color: transparent;
}
.button--white.button--outline {
  color: #fff;
  background-color: transparent;
}
.button--white.button--outline:hover {
  color: #002732;
  background-color: #fff;
  border-color: #fff;
}
.button--dark {
  color: #fff;
  border-color: #002732;
  background-color: #002732;
}
.button--dark:hover {
  color: #002732;
  background-color: #e3e9eb;
  border-color: #e3e9eb;
}
.button--dark.button--outline {
  color: #002732;
  background-color: transparent;
}
.button--dark.button--outline:hover {
  color: #002732;
  background-color: #e3e9eb;
  border-color: #e3e9eb;
}
.button--navy {
  border: 0;
  color: #fff;
  background: #002732; /* Old browsers */
  background: -moz-linear-gradient(left, #00495c 0%, #002732 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #00495c 0%, #002732 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #00495c 0%, #002732 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.button--navy:hover {
  color: #e3e9eb;
}
.button--grey {
  color: #002732;
  background: linear-gradient(206.53deg, #EBF4F6 0.17%, #E3E9EB 79.79%);
  border: 0;
}
.button--inactive {
  pointer-events: none;
  opacity: 0.5;
}

.button-arrow {
  display: block;
  color: #002732;
  font-size: 16px;
  font-size: 0.8rem;
}
.button-arrow:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 14px;
  margin-left: 0.5rem;
  background: no-repeat url("../img/icons/arrow-left-navy.svg") center center;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
}
.button-arrow:hover {
  text-decoration: none;
}
.button-arrow:hover:after {
  transform: translate(10px);
}

.link-arrow {
  display: block;
  color: #002732;
  font-size: 16px;
  font-size: 0.8rem;
}
.link-arrow:after {
  content: "";
  display: inline-block;
  width: 18px;
  height: 14px;
  margin-left: 0.5rem;
  background: no-repeat url("../img/icons/chevron-right.svg") center center;
  background-size: contain;
  transition: transform 0.2s ease-in-out;
}
.link-arrow:hover {
  text-decoration: none;
}
.link-arrow:hover:after {
  transform: translate(10px);
}

.button--store {
  display: inline-block;
  padding: 20px 30px;
  border-radius: 6px;
  background-color: #fff;
}
.button--store .button--store__svg {
  max-width: 150px;
  width: 100%;
}

.title1 {
  font-size: 50px;
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.7px;
  color: #00708d;
  margin-bottom: 2.5rem;
}
@media (max-width: 577px) {
  .title1 {
    font-size: 30px;
    font-size: 1.5rem;
    line-height: 1.17;
    letter-spacing: normal;
    margin-bottom: 1rem;
  }
}

.title2 {
  font-size: 40px;
  font-size: 2rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.7px;
  color: #002732;
  margin-bottom: 1.5rem;
}
@media (max-width: 577px) {
  .title2 {
    font-size: 30px;
    font-size: 1.5rem;
    line-height: 1.17;
    letter-spacing: normal;
    margin-bottom: 0.7rem;
  }
}

.title3 {
  font-size: 30px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 2rem;
}
@media (max-width: 577px) {
  .title3 {
    font-size: 25px;
    font-size: 1.25rem;
  }
}

.title4 {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.35;
  color: #002732;
  margin-bottom: 0.55rem;
}

.subhead {
  display: block;
  color: #00708d;
  margin-bottom: 1rem;
  font-size: 16px;
  font-size: 0.8rem;
}

.header {
  position: fixed;
  min-width: 320px;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: top 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  border-bottom: 1px solid #d4d7d9;
}
@media (min-width: 1170px) {
  .header.-transparent {
    background-color: transparent;
    border-bottom-color: transparent;
  }
}
.header__logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 992px) {
  .header__logo {
    width: auto;
  }
}
.header__logo-link {
  display: inline-block;
  vertical-align: middle;
}
.header__logo-img {
  width: 118px;
}
@media (max-width: 577px) {
  .header__logo-img {
    width: 100px;
  }
  .header__toggler__icon {
    height: 20px !important;
  }
}
.header__toggler__icon {
  display: none;
  vertical-align: middle;
  width: 25px;
  height: 19px;
  margin-right: 10px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
@media (max-width: 1170px) {
  .header__toggler__icon {
    display: inline-block;
  }
}
.header__toggler__icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #00708d;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.header__toggler__icon span:nth-child(1) {
  top: 0px;
}
.header__toggler__icon span:nth-child(2) {
  top: 8.5px;
  height: 2.5px;
}
.header__toggler__icon span:nth-child(3) {
  top: 16.5px;
  height: 2.5px;
}
.header__toggler__icon.-active span:nth-child(1) {
  top: 8.5px;
  transform: rotate(135deg);
}
.header__toggler__icon.-active span:nth-child(2) {
  opacity: 0;
}
.header__toggler__icon.-active span:nth-child(3) {
  top: 8.5px;
  transform: rotate(-135deg);
}
.header__primary-nav {
  position: relative;
  height: calc(100% + 2px);
}
.header__primary-nav__list {
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.header__primary-nav__item {
  height: 100%;
}
.header__primary-nav__link {
  text-decoration: none;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  height: 100%;
  font-size: 14px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #00708d;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: color 0.4s ease-in-out;
}
.header__primary-nav__link:after {
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #002732;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.header__primary-nav__link:hover, .header__primary-nav__link:focus, .header__primary-nav__link:active, .header__primary-nav__link.active {
  color: #002732;
  text-decoration: none;
}
.header__primary-nav__link:hover:after, .header__primary-nav__link:focus:after, .header__primary-nav__link:active:after, .header__primary-nav__link.active:after {
  opacity: 1;
}
@media (max-width: 1170px) {
  .header__primary-nav {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .header__primary-nav__list {
    display: block;
    height: auto;
  }
  .header__primary-nav__link {
    font-size: 18px;
    font-size: 0.9rem;
    padding: 1rem;
  }
  .header__primary-nav__link:after {
    content: none;
  }
}

.footer {
  flex-shrink: 0;
  background-color: #002732;
  padding: 3rem 0;
  font-size: 14px;
  font-size: 0.7rem;
  line-height: 1.86;
}
.footer__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "info nav";
  grid-column-gap: 1.5rem;
}
.footer__info {
  grid-area: info;
  color: #e3e9eb;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}
.footer__info__top {
  margin-bottom: 20px;
}
.footer__info__top .footer__logo {
  margin-bottom: 1rem;
}
.footer__info__help {
  margin-bottom: 40px;
}
.footer__info__help h6 {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 700;
}
.footer__info a {
  font-size: 14px;
  font-size: 0.7rem;
  color: #00dbeb;
}
.footer__info a:hover {
  text-decoration: none;
}
.footer__info a.phone {
  color: #fff;
}
.footer__nav {
  grid-area: nav;
  text-align: right;
}
.footer__nav a {
  color: #fff;
}
.footer__nav__socialmedia {
  color: #EFEEEF;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer__nav__socialmedia ul {
  display: flex;
  justify-content: end;
  gap: 1rem;
}
.footer__nav__socialmedia ul .social-media-btn {
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: inherit;
  transition: all 0.2s ease-in-out;
}
.footer__nav__socialmedia ul .social-media-btn img {
  width: 24px;
  aspect-ratio: 1/1;
  filter: invert(1);
}
.footer__nav__menu {
  margin-top: 44px;
}
@media (max-width: 769px) {
  .footer__wrapper {
    display: block;
  }
  .footer__info {
    margin-bottom: 1.5rem;
  }
  .footer__nav {
    text-align: left;
  }
  .footer__nav__socialmedia ul {
    justify-content: flex-start;
  }
}

.hero-full {
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: no-repeat center center #f5f7e9;
  background-size: cover;
  padding-top: 120px;
}
.hero-full .title1 {
  margin-bottom: 1rem;
}
.hero-full__copy {
  max-width: 730px;
}
@media (max-width: 577px) {
  .hero-full__copy p {
    font-size: 16px;
    font-size: 0.8rem;
  }
}

.cards-container {
  position: relative;
  top: -75px;
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.cards-container .card {
  background-color: #fff;
  max-width: 360px;
  padding: 25px 25px;
  border-radius: 16px;
  text-align: center;
  border: 0.5px solid #A5B0B3;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  row-gap: 15px;
}
.cards-container .card .new-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9747FF;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  top: 25px;
  right: 30px;
}
.cards-container .card .new-notification small {
  font-family: "ToyotaType", sans-serif;
  font-weight: 700;
  font-size: 11px;
  font-size: 0.55rem;
  line-height: 9px;
  text-align: center;
  color: #fff;
}
.cards-container .card__title {
  color: #00708d;
  font-weight: 400;
  font-size: 16px;
  font-size: 0.8rem;
  font-family: "ToyotaType", sans-serif;
}
.cards-container .card__content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  row-gap: 20px;
}
.cards-container .card__copy {
  font-family: "ToyotaType", sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-size: 0.9rem;
  line-height: 28px;
  color: #002732;
}
@media (max-width: 577px) {
  .cards-container {
    padding: 0px 10px;
  }
  .cards-container .card {
    width: 100%;
  }
  .cards-container .card__copy {
    display: none;
  }
}
.cards-container.service-selector {
  top: 0px;
  position: initial;
  padding-top: 50px;
}
.cards-container.service-selector .card {
  max-width: 360px;
}
.cards-container.service-selector .card__content {
  row-gap: 10px;
}
.cards-container.service-selector .card__content p {
  font-size: 18px;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 28px;
}
.cards-container.service-selector .card__content .links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2px;
  row-gap: 10px;
}
.cards-container.service-selector .card__content .links-container a {
  font-size: 13px;
  font-size: 0.65rem;
  line-height: 23px;
}
@media (max-width: 577px) {
  .cards-container.service-selector {
    top: 0px;
  }
  .cards-container.service-selector .card {
    padding: 25px 10px;
  }
  .cards-container.service-selector .card__content .links-container {
    column-gap: 5px;
  }
  .cards-container.service-selector .card__content .links-container a {
    min-width: 7rem;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~ */
/* VEHICLES GALLERY */
/* ~~~~~~~~~~~~~~~~~~~~ */
.vehicles-gallery {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}
.vehicles-gallery .models-slider--single {
  display: none;
}
.vehicles-gallery__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vehicles-gallery__title-container h2 {
  margin-bottom: 0;
}
.vehicles-gallery__title-container a {
  font-size: 18px;
  text-decoration: underline;
}

.vehicles-gallery .slick-list {
  margin: 0px 34px;
}

.vehicles-gallery__list {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.vehicles-gallery__item {
  width: 47%;
  max-width: 348px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.vehicles-gallery__item:hover .vehicles-gallery__title {
  background: #e3e9eb; /* Old browsers */
  background: -moz-linear-gradient(-45deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.vehicles-gallery__item:hover .vehicles-gallery__info .button {
  color: #002732;
  background-color: #ebf4f6;
  border-color: #ebf4f6;
}

.vehicles-gallery__link {
  text-decoration: none;
  color: inherit;
  outline: none;
  display: block;
  height: 100%;
}
.vehicles-gallery__link:hover {
  text-decoration: none;
}

.vehicles-gallery__title {
  box-sizing: border-box;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 3rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.35;
  transition: all 0.4s ease-in-out;
  min-height: 7.5rem;
  border-radius: 6px;
}

.vehicles-gallery__title strong {
  font-weight: 600;
}

.vehicles-gallery__title--claim {
  opacity: 1;
}

.vehicles-gallery__image {
  position: relative;
  top: -50px;
  z-index: 1;
  max-width: 85%;
  height: 130px;
  transition: transform 0.4s ease-in-out;
}

.vehicles-gallery__info {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-top: -40px;
}
.vehicles-gallery__info-name {
  font-size: 25px;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  margin-bottom: 15px;
}
.vehicles-gallery__info-copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vehicles-gallery__info-copy__info {
  font-size: 18px;
  font-size: 0.9rem;
  font-weight: 500;
  min-height: 48px;
}
.vehicles-gallery__info-copy__info p {
  font-weight: normal;
}
.vehicles-gallery__info-copy__info p small {
  font-size: 0.5rem;
}
.vehicles-gallery__info-copy button {
  font-size: 13px;
  font-size: 0.65rem;
  font-family: "ToyotaType";
  font-weight: 600;
  line-height: 23px;
}
.vehicles-gallery__info-small {
  font-size: 10px;
  font-size: 0.5rem;
  color: #738184;
}
.vehicles-gallery__info .button-container {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.vehicles-gallery__info .button {
  display: block;
  margin-left: auto;
  margin-top: 10px;
}

@media (max-width: 577px) {
  .vehicles-gallery__list {
    display: none;
  }
  .vehicles-gallery .models-slider--single {
    display: block;
    width: 100%;
    padding-top: 15px;
    margin-bottom: 50px;
  }
  .vehicles-gallery .models-slider--single__list-item {
    padding: 0;
  }
  .vehicles-gallery .models-slider--single .button {
    width: 130px;
  }
  .vehicles-gallery .models-slider--single .row .models-slider--single__list-item-data__action {
    align-items: center;
  }
  .vehicles-gallery .models-slider--single .row .title3 {
    margin-bottom: 10px;
  }
  .vehicles-gallery .slick-list {
    margin: 0;
  }
  .vehicles-gallery__item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .vehicles-gallery__item .vehicles-gallery__title {
    background: #e3e9eb; /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(227, 233, 235, 0.3) 0%, #e3e9eb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .vehicles-gallery__item .vehicles-gallery__info .button {
    margin-right: auto;
    background-color: #ebf4f6;
    border-color: #ebf4f6;
  }
}
@media (min-width: 576px) {
  .vehicles-gallery.one_personal .vehicles-gallery__item {
    display: flex;
    flex-direction: column;
  }
  .vehicles-gallery.one_personal .vehicles-gallery__info .vehicles-gallery__link {
    height: unset;
  }
}
@media (min-width: 768px) {
  .vehicles-gallery.one_personal .vehicles-gallery__item {
    display: flex;
    flex-direction: column;
  }
  .vehicles-gallery.one_personal .vehicles-gallery__item .vehicles-gallery__info .button {
    display: inline-block;
    margin-left: 110px;
    margin-top: -30px;
  }
  .vehicles-gallery.one_personal .vehicles-gallery__info .vehicles-gallery__link {
    height: 100%;
  }
}
.experiencia-kinto {
  position: relative;
  min-height: 20rem;
  overflow: hidden;
}
.experiencia-kinto__back {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 50vw;
  height: 100%;
}
.experiencia-kinto__slider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.experiencia-kinto__slider-slide {
  width: 100%;
  height: 100%;
  display: grid !important;
  grid-template-columns: 1.2fr 0.8fr;
  align-items: center;
}
.experiencia-kinto__slider-slide__img img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  height: 460px;
}
.experiencia-kinto__slider-slide__info {
  padding: 2rem 0 2rem 2rem;
}
.experiencia-kinto__slider-slide__info .copy {
  margin-bottom: 2rem;
}
.experiencia-kinto__slider-slide__info .copy p {
  margin-bottom: 0.8rem;
}
.experiencia-kinto__slider-slide__info .button-arrow {
  margin-top: 3rem;
}
.experiencia-kinto .gallery__bottom {
  display: inline-block;
  margin-left: calc(50% + 1rem);
}
@media (max-width: 769px) {
  .experiencia-kinto__back {
    right: 0;
    left: 0;
    bottom: 70%;
    width: 100%;
    top: auto;
  }
  .experiencia-kinto__slider .slick-list {
    margin: 0 1.5rem;
  }
  .experiencia-kinto__slider-slide {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .experiencia-kinto__slider-slide__info {
    padding: 2rem 0 0;
  }
  .experiencia-kinto .gallery__bottom {
    display: block;
    margin-left: 0;
  }
}

.models-services-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d4d7d9;
  margin-bottom: 50px;
}
.models-services-selector__list {
  display: flex;
  justify-content: center;
  column-gap: 90px;
  align-items: center;
}
.models-services-selector__item {
  text-align: center;
  height: 40px;
  width: 180px;
  font-family: "ToyotaType";
  font-weight: 600;
  color: #00708d;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  transition: all 200ms ease-out;
}
.models-services-selector__item:hover {
  color: #002732;
  border-bottom: 4px solid #002732;
  transition: all 200ms ease-out;
}
.models-services-selector__item.selected {
  color: #002732;
  border-bottom: 4px solid #002732;
  transition: all 200ms ease-out;
}
@media (max-width: 577px) {
  .models-services-selector {
    justify-content: start;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  .models-services-selector::-webkit-scrollbar {
    display: none;
  }
  .models-services-selector__list {
    column-gap: 0px;
  }
}

.share-section, .one-personal-section, .one-business-section {
  display: none;
  padding-bottom: 100px;
}
.share-section.show, .one-personal-section.show, .one-business-section.show {
  display: grid;
}
.share-section .disclaimer, .one-personal-section .disclaimer, .one-business-section .disclaimer {
  line-height: 0.6;
  margin-top: 20px;
  margin-bottom: 20px;
}
.share-section .disclaimer small, .one-personal-section .disclaimer small, .one-business-section .disclaimer small {
  color: #738184;
  font-size: 0.5rem;
}

.models-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row: auto;
  grid-column-gap: 1.5rem;
  grid-row-gap: 4rem;
  width: 100%;
}
.models-grid__item {
  width: 100%;
  padding-top: 20px;
}
.models-grid__item-offer-tag {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 2px 10px 2px 20px;
  font-size: 0.5rem;
  color: white;
  line-height: 14px;
}
.models-grid__item-offer-tag:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 9px solid white;
}
.models-grid__item-img {
  display: block;
  max-width: 100%;
  height: 130px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}
.models-grid__item-small {
  display: block;
  font-size: 10px;
  font-size: 0.5rem;
  color: #738184;
}
.models-grid__item-data {
  margin-top: 1rem;
}
.models-grid__item-data__title {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.models-grid__item-data__price {
  font-size: 14px;
  font-size: 0.7rem;
}
.models-grid__item-data__buy {
  text-align: right;
}
.models-grid__item-data__buy.one_personal {
  margin-top: 20px;
}
.models-grid__item-data__buy a {
  padding: 3px 30px;
}
.models-grid__item-data .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
}
.models-grid__item-data .row.one-personal-info {
  display: block;
}
@media (max-width: 993px) {
  .models-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 769px) {
  .models-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 577px) {
  .models-grid {
    grid-template-columns: 1fr;
  }
}

.sections-grid {
  display: grid;
  grid-template-columns: 0.65fr 1.35fr;
  grid-gap: 50px;
}
.sections-grid__app {
  background-color: #e3e9eb;
  padding: 35px;
  border-radius: 6px;
}
.sections-grid__app__illus {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  max-width: 300px;
}
.sections-grid__app__copy {
  margin-bottom: 1.5rem;
}
.sections-grid__app .button--store:not(:last-child) {
  margin-bottom: 1rem;
}
.sections-grid__sections {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 2rem 0;
}
.sections-grid__sections .separator {
  margin: 2rem 0;
}
.sections-grid__sections__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.sections-grid__sections__row-left, .sections-grid__sections__row-right {
  width: 46%;
}
.sections-grid__sections__row-left__illus, .sections-grid__sections__row-right__illus {
  display: block;
  max-width: 100%;
  height: auto;
}
.sections-grid__sections__row.-reverse {
  flex-direction: row-reverse;
}
@media (max-width: 769px) {
  .sections-grid {
    display: block;
  }
  .sections-grid__sections {
    display: block;
  }
}
@media (max-width: 577px) {
  .sections-grid__sections__row {
    display: block;
  }
  .sections-grid__sections__row-left {
    width: 100%;
    margin-bottom: 1rem;
  }
  .sections-grid__sections__row-right {
    width: 100%;
  }
}

.experiencias-grid {
  list-style: none;
  padding: 0;
  margin: 0 0 4rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
.experiencias-grid__item {
  border-radius: 6px;
  padding: 1rem;
  transition: box-shadow 0.3s ease-in-out;
  display: grid;
}
.experiencias-grid__item:hover {
  box-shadow: 0 0px 25px 2px rgba(0, 0, 0, 0.12), 0 0px 14px -4px rgba(0, 0, 0, 0.017);
}
.experiencias-grid__item-img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 0.5rem;
}
.experiencias-grid__item-copy {
  font-size: 0.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.experiencias-grid__item-copy p {
  margin-bottom: 0.25rem;
}
.experiencias-grid__item-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
}
.experiencias-grid__item-data__price strong {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 300;
}
.experiencias-grid__item-data__price p {
  font-size: 10px;
  font-size: 0.5rem;
  color: #738184;
}
.experiencias-grid__item-data__more {
  text-align: right;
}
@media (max-width: 1170px) {
  .experiencias-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 900px) {
  .experiencias-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 577px) {
  .experiencias-grid {
    grid-template-columns: 1fr;
    grid-row-gap: 2.5rem;
    margin-top: 1.5rem;
  }
  .experiencias-grid__item {
    padding: 0;
  }
  .experiencias-grid__item:hover {
    box-shadow: none;
  }
}

.models-slider--single__list {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}
.models-slider--single__list .row {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-template-areas: "copy pic" "action pic";
  align-items: center;
}
@media (max-width: 769px) {
  .models-slider--single__list .row {
    display: block;
  }
}
.models-slider--single__list-item {
  padding: 0 0.5rem;
}
.models-slider--single__list-item-data__copy {
  grid-area: copy;
  margin-top: 10px;
}
.models-slider--single__list-item-data__copy .title2 {
  margin-bottom: 0.7rem;
}
.models-slider--single__list-item-data__copy .title3 {
  margin-bottom: 0.7rem;
}
.models-slider--single__list-item-data__copy p {
  margin-bottom: 1rem;
  height: 50px;
}
.models-slider--single__list-item-data__action {
  grid-area: action;
}
.models-slider--single__list-item-data__action-price {
  font-weight: normal;
}
.models-slider--single__list-item-data__action-price small {
  font-size: 14px;
  font-size: 0.7rem;
}
.models-slider--single__list-item-data__action-franchise {
  display: block;
  font-size: 10px;
  font-size: 0.5rem;
  color: #738184;
  margin: 0.5rem 0 1.5rem;
}
.models-slider--single__list-item-data__action.one-personal {
  flex-direction: column;
  align-items: flex-start !important;
}
.models-slider--single__list-item-data__action.one-personal a {
  align-self: flex-end;
  margin-top: 1rem;
}
.models-slider--single__list-item-pic {
  grid-area: pic;
  position: relative;
}
.models-slider--single__list-item-pic__back {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 80%;
  height: 70%;
  border-radius: 6px;
  background: #e3e9eb; /* Old browsers */
  background: -moz-linear-gradient(-45deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.models-slider--single__list-item-pic__img {
  display: block;
  max-width: 100%;
  max-height: 330px;
  height: auto;
  margin: 3rem auto 0;
}
.models-slider--single__list-item-offer-tag {
  padding: 4px 8px;
  max-width: fit-content;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: #FFFFFF;
}
.models-slider--single__list-item .models-slider__disclaimer-text {
  color: #738184;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  margin-top: 20px;
}
@media (max-width: 769px) {
  .models-slider--single__list-item-pic {
    height: 250px;
  }
  .models-slider--single__list-item-pic__back {
    height: auto;
    top: -100px;
    bottom: 30%;
    width: 100%;
  }
  .models-slider--single__list-item-pic__img {
    margin-top: 2rem;
  }
  .models-slider--single__list-item-data__action {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .models-slider--single__list-item-data__action-franchise {
    margin-bottom: 0;
  }
}
@media (max-width: 577px) {
  .models-slider--single__list-item-pic {
    height: 140px;
  }
}

.body-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  margin-bottom: 50px;
}
.body-header h1 {
  font-family: "ToyotaType";
  font-size: 50px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 55px;
  color: #00708d;
  max-width: 900px;
  text-align: center;
}
.body-header p {
  font-size: 18px;
  font-size: 0.9rem;
  line-height: 28px;
  color: #002732;
  max-width: 900px;
}
@media (max-width: 577px) {
  .body-header h1 {
    font-size: 30px;
    font-size: 1.5rem;
    line-height: 35px;
  }
}

.info-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "pic data";
  grid-gap: 2rem;
  align-items: center;
  margin-bottom: 5rem;
}
.info-block:last-child {
  margin-bottom: 0;
}
.info-block__pic {
  grid-area: pic;
  padding: 3rem 0 0 3rem;
}
.info-block__pic-shadow {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  background: #e3e9eb; /* Old browsers */
  background: -moz-linear-gradient(-45deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(227, 233, 235, 0.1) 0%, #e3e9eb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.info-block__pic-img {
  position: relative;
  top: -3rem;
  left: -3rem;
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}
.info-block__data {
  grid-area: data;
  max-width: 380px;
  margin-left: 90px;
}
.info-block__data.plans-block {
  margin-left: 0px;
  max-width: 440px;
}
.info-block__data-title {
  font-size: 40px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.7px;
  margin-bottom: 1.5rem;
}
.info-block__data-title.ocean {
  color: #00708d;
  font-weight: 400;
}
.info-block__data-subtitle {
  font-size: 16px;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #00708d;
  font-weight: normal;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}
.info-block__data-cta {
  margin-top: 30px;
}
.info-block__data-cta a {
  font-family: "ToyotaType";
  font-size: 20px;
  font-size: 1rem;
  text-decoration: underline;
  color: #00708d;
  font-weight: 400;
}
.info-block__data-plans-container {
  margin-top: 20px;
}
.info-block__data-plans-container ol {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.info-block__data-plans-container ol .plan {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.info-block__data-plans-container ol .plan img {
  max-width: 36px;
  max-height: 36px;
}
.info-block__data-plans-container ol .plan__copy p {
  font-size: 20px;
  font-size: 1rem;
  line-height: 27px;
}
.info-block__data-plans-container ol .plan__copy p b {
  font-weight: 400;
}
.info-block.-reverse {
  grid-template-areas: "data pic";
}
.info-block.-reverse .info-block__pic {
  padding: 3rem 3rem 0 0;
}
.info-block.-reverse .info-block__pic-img {
  right: -3rem;
  left: auto;
}
.info-block .download-section {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.info-block .download-section .stores-section {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 30px;
}
.info-block .download-section .stores-section .button--store {
  background-color: #fff;
  border: 1px solid #EBECED;
}
.info-block .download-section .qr-section {
  margin-top: 30px;
}
.info-block .download-section .qr-section img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 769px) {
  .info-block {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "pic" "data";
  }
  .info-block.-reverse {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "data" "pic";
  }
}
@media (max-width: 577px) {
  .info-block {
    margin-bottom: 3rem;
  }
  .info-block__pic {
    padding: 2rem 0 0 2rem;
  }
  .info-block__pic-img {
    top: -2rem;
    left: -2rem;
  }
  .info-block__data {
    margin-left: 0px;
  }
  .info-block__data-title {
    font-size: 25px;
    font-size: 1.25rem;
  }
  .info-block__data-copy p {
    font-size: 16px;
    font-size: 0.8rem;
    line-height: 28px;
  }
  .info-block.-reverse .info-block__pic {
    padding: 2rem 2rem 0 0;
  }
  .info-block.-reverse .info-block__pic-img {
    right: -2rem;
  }
}

.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}
.accordion b {
  font-weight: 400;
}
.accordion__item {
  overflow: hidden;
}
.accordion__item__button {
  min-height: 5rem;
  width: 100%;
  cursor: pointer;
  padding: 0;
  border: none;
  border-bottom: 1px solid #738184;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: inherit;
  text-decoration: none;
  outline: none;
}
@media (max-width: 769px) {
  .accordion__item__button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.accordion__item__button__text {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.4;
  letter-spacing: -0.29px;
  font-family: "ToyotaType";
}
@media (max-width: 769px) {
  .accordion__item__button__text {
    font-size: 0.9rem;
    line-height: 2;
  }
}

.accordion__item__button__icon {
  flex: 0 0 auto;
  height: calc(2.5rem + 2px);
  width: calc(2.5rem + 2px);
  margin-top: -1px;
  margin-bottom: -1px;
}
.accordion__item__button__icon .icon {
  position: relative;
  height: 100%;
  width: 100%;
}
.accordion__item__button__icon .icon:before, .accordion__item__button__icon .icon:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-size: 1.5rem;
  font-weight: 300;
  transition: opacity 0.4s ease-in-out;
  color: #738184;
}
.accordion__item__button__icon .icon:before {
  content: "+";
  opacity: 1;
}
.accordion__item__button__icon .icon:after {
  content: "";
  opacity: 0;
  height: 0;
  width: 17px;
  border-top: 1px solid;
  margin-top: 4px;
}

.accordion__item__body-wrapper {
  overflow: hidden;
  height: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.4s ease-in-out;
}

.accordion__item__body {
  box-sizing: border-box;
  padding: 1rem;
}
.accordion__item__body__container {
  box-sizing: border-box;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
}
.accordion__item__body__container * {
  color: inherit;
  font-size: 0.9rem;
}
.accordion__item__body__container p {
  margin-bottom: 1rem;
}
.accordion__item__body__container p:last-child {
  margin-bottom: 0;
}
@media (max-width: 577px) {
  .accordion__item__body__container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.accordion__item.-active .accordion__item__body-wrapper {
  border-bottom-color: #738184;
}
.accordion__item.-active .accordion__item__button__icon .icon:before {
  opacity: 0;
}
.accordion__item.-active .accordion__item__button__icon .icon:after {
  opacity: 1;
}

.accordion--open .accordion__item__body-wrapper {
  height: auto;
}

.accordion-wrapper .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.accordion-wrapper .title-container h3 {
  margin-bottom: 0px;
}
.accordion-container {
  list-style: none;
  padding: 0;
  margin: 0;
}
.accordion-container__item__title {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: solid 2px #e3e9eb;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.accordion-container__item .accordion__item__button {
  height: 2rem;
}
.accordion-container__item .accordion__item__button__text {
  font-size: 0.7rem;
  line-height: 1.75;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
}
.accordion-container__item .accordion__item__button__icon {
  height: 2rem;
  width: 2rem;
}
.accordion-container__item .accordion__item__button__icon .icon:before, .accordion-container__item .accordion__item__button__icon .icon:after {
  font-size: 24px;
  line-height: 0;
}
.accordion-container__item .accordion__item__button__icon .icon:before {
  margin-top: -0.125rem;
}
.accordion-container__item .accordion__item__button__icon .icon:after {
  margin-top: 0;
  width: 11px;
}
.accordion-container__item .accordion__item__body__container {
  padding: 0;
}

.form__wrapper:last-of-type {
  margin-bottom: 2.25rem;
}
@media (max-width: 577px) {
  .form__wrapper .cols {
    display: initial;
  }
}
.form__wrapper .col {
  width: 100%;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .form__wrapper .col {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.form__wrapper .col:first-child {
  margin-left: 0;
}
.form__wrapper .col:last-child {
  margin-right: 0;
}
.form__wrapper .col .form__input-wrapper {
  margin-bottom: 0;
}
.form__input-aside {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 22px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.form__input-wrapper {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.form__input-wrapper:first-child {
  margin-top: 1rem;
}
.form__input-wrapper--select .form__input {
  appearance: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
}
.form__input-wrapper--select .form__input-helper {
  position: relative;
}
.form__input-wrapper--select .form__input-helper:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.1666666667rem);
  right: 0.3333333333rem;
  display: block;
  height: 0.3333333333rem;
  width: 0.3333333333rem;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg) translateY(-50%);
}
.form__input-wrapper--radio .form__input {
  width: initial;
  vertical-align: middle;
  margin: 0;
  margin-right: 0.125rem;
}
.form__input-wrapper--radio .form__label {
  position: initial;
  vertical-align: middle;
}
.form__label {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  transition: all 0.4s ease-in-out;
  color: #00708d;
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 500;
  pointer-events: none;
}
.form__label.textarea {
  bottom: unset;
  top: 0px;
}
.form__label-error {
  color: #ec0000;
  display: none;
  font-size: x-small;
  font-weight: 900;
  margin-top: -5px;
}
.form__label--hidden {
  display: none;
}
.form__input {
  height: 1.5rem;
}
.form__input:active + .form__label, .form__input:focus + .form__label, .form__input.-interacted + .form__label, .form__input[type=date] + .form__label {
  bottom: 1.5rem;
}
.form__input:active + .form__label.textarea, .form__input:focus + .form__label.textarea, .form__input.-interacted + .form__label.textarea, .form__input[type=date] + .form__label.textarea {
  top: -20px;
  bottom: unset;
}
.form__input:active + label + .form__label, .form__input:focus + label + .form__label, .form__input.-interacted + label + .form__label, .form__input[type=date] + label + .form__label {
  bottom: 1.5rem;
}
.form__input, .form__textarea {
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #738184;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
}
.form__textarea {
  resize: none;
  margin-top: 0.6em;
}
.form__textarea:active + .form__label, .form__textarea:focus + .form__label, .form__textarea.-interacted + .form__label, .form__textarea[type=date] + .form__label {
  bottom: 5.2rem;
}
.form__textarea:active + label + .form__label, .form__textarea:focus + label + .form__label, .form__textarea.-interacted + label + .form__label, .form__textarea[type=date] + label + .form__label {
  bottom: 5.2rem;
}
.form__submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 577px) {
  .form__submit {
    margin-top: 1.5rem;
  }
}
.form__title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 2.25rem;
  margin-bottom: 2rem;
}
.form__title + .cols {
  margin-top: -2rem;
}
.form__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 2rem;
}
.form__row > * {
  margin-bottom: 1rem;
}
@media (max-width: 577px) {
  .form__row {
    display: block;
  }
  .form__row > * {
    margin-bottom: 0;
  }
  .form__row-submit {
    text-align: center;
  }
}
.form--contact {
  margin-bottom: 3.5rem;
}
.form__checkbox input[type=checkbox] {
  display: none;
}
.form__checkbox input[type=checkbox]:checked + label::after {
  display: block;
}
.form__checkbox label {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
.form__checkbox label::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  border: 1px solid #002732;
  border-radius: 3px;
  margin-right: 22px;
}
.form__checkbox label::after {
  content: "";
  display: none;
  height: 13px;
  width: 5px;
  left: 10px;
  top: 4px;
  border-right: 1px solid #002732;
  border-bottom: 1px solid #002732;
  position: absolute;
  transform: rotate(45deg);
}
@media (max-width: 769px) {
  .form__checkbox {
    margin-bottom: 1rem;
  }
}
.form__disclaimer {
  display: block;
  margin-top: 2rem;
  font-size: 10px;
  font-size: 0.5rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: none;
}
.modal .modal-box {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  max-width: 800px;
}
.modal .modal-box.small {
  max-width: 500px;
}
.modal .modal-box.medium {
  max-width: 800px;
}
.modal .modal-box.large {
  max-width: 1110px;
}
.modal .modal-box .modal-header {
  padding: 1rem 4rem 1rem 2rem;
  background-color: #e3e9eb;
  border-radius: 6px;
}
.modal .modal-box .modal-header .title3 {
  margin-bottom: 0;
  font-weight: 500;
}
.modal .modal-box .modal-header .modal-close {
  border: 0;
  background: none;
  outline: none;
  margin: 0;
  position: absolute;
  top: calc(50% - 14px);
  right: 2rem;
  z-index: 10;
  font-size: 20px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 0.8;
  padding: 5px;
  color: #002732;
  cursor: pointer;
}
.modal .modal-box .modal-content {
  padding: 1.5rem 2rem;
}
.modal .modal-box .modal-content ul {
  margin-left: 9px;
}
.modal .modal-box .modal-content ul, .modal .modal-box .modal-content ul li {
  list-style: disc;
}
.modal .modal-box .modal-footer {
  background-color: #e3e9eb;
  padding: 15px 30px;
  font-weight: 300;
  font-size: 15px;
  font-size: 0.75rem;
  text-align: center;
}
.modal .modal-box .modal-footer:empty {
  padding-top: 0;
  display: none;
}
@media (max-width: 769px) {
  .modal {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    display: none;
  }
  .modal:not(.prevent-fs) {
    padding: 0;
    overflow: auto;
    height: 100%;
  }
  .modal:not(.prevent-fs) .modal-box {
    border: 0;
    border-radius: 0;
    position: static;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .modal:not(.prevent-fs) .modal-box .push-footer {
    flex: 1 0 auto;
  }
  .modal:not(.prevent-fs) .modal-box .push-footer .modal-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.7rem 2.5rem 0.7rem 1rem;
    border-radius: 0;
  }
  .modal:not(.prevent-fs) .modal-box .push-footer .modal-header .modal-close {
    top: calc(50% - 17px);
    padding: 10px;
    right: 6px;
  }
  .modal:not(.prevent-fs) .modal-box .push-footer .modal-content {
    padding: 4rem 0.7rem 1rem;
  }
  .modal:not(.prevent-fs) .modal-box .push-footer.small, .modal:not(.prevent-fs) .modal-box .push-footer.medium, .modal:not(.prevent-fs) .modal-box .push-footer.big {
    max-width: none;
  }
  .modal:not(.prevent-fs) .modal-box .modal-footer {
    flex-shrink: 0;
  }
  .modal.prevent-fs {
    padding: 0.5rem;
  }
  .modal.prevent-fs .modal-box {
    border: 0;
  }
  .modal.prevent-fs .modal-box .modal-header {
    padding: 0.7rem 3rem 0.7rem 1rem;
  }
  .modal.prevent-fs .modal-box .modal-header .modal-close {
    right: 1rem;
  }
  .modal.prevent-fs .modal-box .modal-content {
    padding: 0.7rem 1rem;
  }
}
.modal__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
  grid-template-areas: "info form" "title form";
}
.modal__row-title {
  grid-area: title;
  padding-right: 4rem;
  align-self: end;
}
.modal__row-info {
  grid-area: info;
  padding-right: 4rem;
}
.modal__row-form {
  grid-area: form;
  border-left: 1px solid #002732;
  padding-left: 4rem;
}
@media (max-width: 769px) {
  .modal__row {
    grid-column-gap: 0;
    grid-row-gap: 2rem;
    grid-template-areas: "info info" "title title" "form form";
  }
  .modal__row-form {
    border-left: 0;
    padding-left: 0;
    margin-bottom: 1rem;
  }
  .modal__row-title, .modal__row-info {
    padding-right: 0;
  }
}
@media (max-width: 577px) {
  .modal__row-title .title3 {
    font-size: 22px;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  .modal__row-info-schedule {
    display: none;
  }
}

.form__input:active + {
  bottom: 1.5rem;
}

#modal_one_personal .info-section {
  display: flex;
  flex-direction: column;
  max-height: 87%;
}
#modal_one_personal .info-section .form__disclaimer {
  margin-top: auto;
}

.modal#promo-modal .modal-wrapper, .modal#onepersonalsim-modal .modal-wrapper {
  max-width: 100%;
}
.modal#promo-modal .modal-box, .modal#onepersonalsim-modal .modal-box {
  border-radius: 16px;
}
.modal#promo-modal .modal-content, .modal#onepersonalsim-modal .modal-content {
  padding: 0;
}
.modal#promo-modal .modal-content .modal-body, .modal#onepersonalsim-modal .modal-content .modal-body {
  display: flex;
  position: relative;
  flex-direction: row;
}
.modal#promo-modal .modal-content .modal-body .modal-close, .modal#onepersonalsim-modal .modal-content .modal-body .modal-close {
  position: absolute;
  top: 26px;
  right: 25px;
  cursor: pointer;
  z-index: 10;
  text-shadow: 0px 0px 1px #FFFFFF;
}
.modal#promo-modal .modal-content .modal-body .left-section, .modal#onepersonalsim-modal .modal-content .modal-body .left-section {
  display: flex;
  align-items: center;
  max-width: 45%;
}
.modal#promo-modal .modal-content .modal-body .left-section img, .modal#onepersonalsim-modal .modal-content .modal-body .left-section img {
  height: 100%;
  min-width: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.modal#promo-modal .modal-content .modal-body .right-section, .modal#onepersonalsim-modal .modal-content .modal-body .right-section {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
}
.modal#promo-modal .modal-content .modal-body .right-section .title3, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .title3 {
  max-width: 410px;
}
.modal#promo-modal .modal-content .modal-body .right-section .text-content, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .text-content {
  margin-bottom: 56px;
  font-size: 16px;
}
.modal#promo-modal .modal-content .modal-body .right-section .button-wrapper, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .button-wrapper {
  display: flex;
}
.modal#promo-modal .modal-content .modal-body .right-section .button-wrapper .modal-close, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .button-wrapper .modal-close {
  position: relative;
  right: 0;
  top: 0;
}
@media (max-width: 769px) {
  .modal#promo-modal .modal-content .modal-body .right-section, .modal#onepersonalsim-modal .modal-content .modal-body .right-section {
    padding: 40px 24px;
    width: 100%;
  }
  .modal#promo-modal .modal-content .modal-body .right-section .title3, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .title3 {
    margin-bottom: 20px;
  }
  .modal#promo-modal .modal-content .modal-body .right-section .text-content, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .text-content {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .modal#promo-modal .modal-content .modal-body .right-section .button-wrapper, .modal#onepersonalsim-modal .modal-content .modal-body .right-section .button-wrapper {
    justify-content: center;
  }
}
@media (max-width: 769px) {
  .modal#promo-modal .modal-content .modal-body, .modal#onepersonalsim-modal .modal-content .modal-body {
    flex-direction: column;
  }
  .modal#promo-modal .modal-content .modal-body .modal-close, .modal#onepersonalsim-modal .modal-content .modal-body .modal-close {
    top: 15px;
    right: 20px;
  }
  .modal#promo-modal .modal-content .modal-body .left-section, .modal#promo-modal .modal-content .modal-body .right-section, .modal#onepersonalsim-modal .modal-content .modal-body .left-section, .modal#onepersonalsim-modal .modal-content .modal-body .right-section {
    width: 100%;
    overflow: hidden;
    max-width: 100%;
  }
  .modal#promo-modal .modal-content .modal-body .left-section, .modal#onepersonalsim-modal .modal-content .modal-body .left-section {
    max-height: 200px;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .modal#promo-modal .modal-content .modal-body .left-section img, .modal#onepersonalsim-modal .modal-content .modal-body .left-section img {
    height: 100%;
  }
}
@media (max-width: 769px) {
  .modal#promo-modal, .modal#onepersonalsim-modal {
    padding: 100px 16px 0;
  }
}

.modal.personal-data-modal .modal-wrapper {
  max-width: 100%;
}
.modal.personal-data-modal .modal-box {
  border-radius: 16px;
  padding: 30px;
  max-width: 570px;
}
.modal.personal-data-modal .modal-box .form__input-wrapper, .modal.personal-data-modal .modal-box .form__input-aside, .modal.personal-data-modal .modal-box .form__text, .modal.personal-data-modal .modal-box .disclaimer {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.modal.personal-data-modal .modal-box .form__row {
  margin-top: 1rem;
}
.modal.personal-data-modal .modal-box .form__row div, .modal.personal-data-modal .modal-box .form__row aside {
  margin-bottom: 0;
}
.modal.personal-data-modal .modal-content {
  padding: 0;
}
.modal.personal-data-modal .modal-content .modal-body {
  display: flex;
  position: relative;
  flex-direction: row;
}
.modal.personal-data-modal .modal-content .modal-body .form-title {
  font-size: 30px;
  line-height: 37px;
  color: #002732;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}
.modal.personal-data-modal .modal-content .modal-body .form__label {
  color: #002732;
}
.modal.personal-data-modal .modal-content .modal-body .form__input, .modal.personal-data-modal .modal-content .modal-body .form__textarea {
  border-bottom: 1px solid #002732;
  font-family: "ToyotaType", sans-serif;
}
.modal.personal-data-modal .modal-content .modal-body .form__text {
  font-size: 16px;
  line-height: 21px;
  margin: 32px 0;
}
.modal.personal-data-modal .modal-content .modal-body .form__row {
  justify-content: center;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer {
  font-size: 12px;
  line-height: 20px;
  display: flex;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .checkbox-text {
  font-size: 12px;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 10px;
  user-select: none;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  border: solid 1px #002732;
  background-color: #fff;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid #002732;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox .error {
  position: absolute;
  top: 1rem;
  white-space: nowrap;
  font-size: 0.6rem;
  line-height: 1.2rem;
  color: #002732;
  font-weight: 500;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox input:checked ~ .checkmark {
  background-color: #e3e9eb;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.modal.personal-data-modal .modal-content .modal-body .disclaimer .custom-checkbox:hover input ~ .checkmark {
  background-color: #e3e9eb;
}
@media (max-width: 769px) {
  .modal.personal-data-modal .modal-content .modal-body {
    flex-direction: column;
  }
}
@media (max-width: 769px) {
  .modal.personal-data-modal {
    padding: 0 !important;
    background-color: #fff;
  }
  .modal.personal-data-modal .modal-box {
    padding: 50px 15px;
  }
  .modal.personal-data-modal .modal-content .modal-body {
    align-items: center;
    width: 100vw;
  }
  .modal.personal-data-modal .modal-content .modal-body .form-title {
    max-width: 266px;
    margin-bottom: 40px;
    align-self: center;
    font-size: 25px;
    line-height: 32px;
  }
  .modal.personal-data-modal .modal-content .modal-body .form__input-wrapper, .modal.personal-data-modal .modal-content .modal-body .form__input-aside {
    margin-top: 1rem;
  }
  .modal.personal-data-modal .modal-content .modal-body .form__text {
    margin: 16px 0;
  }
}

.stations-map {
  background-color: #fff;
}
.stations-map.pad-top {
  padding-top: 3.5rem;
}
.stations-map .map {
  height: 35rem;
  background-color: #e3e9eb;
}
@media (max-width: 577px) {
  .stations-map .map {
    height: 17.5rem;
  }
}
.stations-map .map [aria-label=Map] {
  position: relative !important;
}
.stations-map__header, .stations-map__all {
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 993px) {
  .stations-map__header, .stations-map__all {
    display: block;
  }
}
@media (max-width: 577px) {
  .stations-map__header, .stations-map__all {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}
.stations-map__header__title, .stations-map__all__title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0;
  color: #00708d;
}
@media (max-width: 993px) {
  .stations-map__header__title, .stations-map__all__title {
    width: 40%;
  }
}
@media (max-width: 769px) {
  .stations-map__header__title, .stations-map__all__title {
    font-size: 1rem;
    width: 33%;
  }
}
@media (max-width: 577px) {
  .stations-map__header__title, .stations-map__all__title {
    width: 100%;
    font-size: 1.5rem;
  }
}
@media (max-width: 993px) {
  .stations-map__header .stations-map__location, .stations-map__all .stations-map__location {
    margin: 2rem 0 1rem;
  }
}
@media (max-width: 577px) {
  .stations-map__header .stations-map__location, .stations-map__all .stations-map__location {
    margin: 1rem 0 0;
  }
}
.stations-map__header .stations-map__location .stations-map__current-location, .stations-map__all .stations-map__location .stations-map__current-location {
  font-size: 16px;
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .stations-map__all {
    height: 6.5rem;
  }
}
@media (max-width: 577px) {
  .stations-map__all {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.stations-map__all__title {
  font-size: 1.5rem;
}
@media (max-width: 769px) {
  .stations-map__all__title {
    width: 100%;
  }
}
@media (max-width: 577px) {
  .stations-map__all__title {
    margin-bottom: 1.5rem;
  }
}
.stations-map__all .button--small {
  min-width: 9.75rem;
}
@media (max-width: 577px) {
  .stations-map__location {
    margin-top: 1.25rem;
  }
}
.stations-map__search-location {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #e3e9eb;
  vertical-align: middle;
}
.stations-map__search-location::before {
  content: "";
  top: 50%;
  left: 0;
  width: 17px;
  height: 17px;
  display: block;
  position: absolute;
  transform: translateY(-50%);
  background: url(../img/icons/search.svg) no-repeat center;
  background-size: contain;
}
@media (max-width: 577px) {
  .stations-map__search-location {
    display: block;
    margin-top: 1.75rem;
    max-width: 20rem;
  }
}
@media (min-width: 768px) {
  .stations-map__search-location {
    margin-left: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .stations-map__search-location {
    width: calc((768px - 2rem) / 3);
  }
}
@media (min-width: 992px) and (max-width: 1119px) {
  .stations-map__search-location {
    width: calc((992px - 2rem) / 3);
  }
}
@media (min-width: 1200px) {
  .stations-map__search-location {
    width: calc((1100px - 2rem) / 3);
  }
}
@media (min-width: 1500px) {
  .stations-map__search-location {
    width: calc((1200px - 2rem) / 3);
  }
}
.stations-map__search-location .select2-container {
  width: 100% !important;
}
.stations-map__search-location .select2-container .select2-selection--single {
  border: none !important;
  padding-left: 1.5rem;
  padding-right: 0;
  background-color: transparent;
}
.stations-map__search-location .select2-container .select2-selection--single:focus {
  border: none;
  outline: 0;
  box-shadow: none;
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__placeholder, .stations-map__search-location .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #191919;
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__rendered {
  display: flex;
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__rendered:focus {
  border: none;
  outline: 0;
  box-shadow: none;
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__rendered span:last-child {
  width: 90%;
  overflow: hidden;
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__clear {
  right: 0;
  height: 90%;
  width: 40px;
  font-size: 0;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: flex-end;
  background: rgba(255, 255, 255, 0);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 59%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(0%, rgba(255, 255, 255, 0)), color-stop(59%, rgb(255, 255, 255)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 59%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 59%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 59%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 59%);
}
.stations-map__search-location .select2-container .select2-selection--single .select2-selection__clear::before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  background: url("../img/icons/clear.svg") no-repeat center;
  background-size: contain;
}
.stations-map__search-location__label {
  line-height: 0;
  position: absolute;
  left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
}
.stations-map__search-location__label img {
  max-width: 100%;
}
.stations-map__search-location__input {
  display: block;
  width: 100%;
  height: 1.75rem;
  border: none;
  outline: none;
  padding-left: 2rem;
  padding-right: 0.5rem;
}
.stations-map__map-wrapper {
  position: relative;
}
.stations-map__map-wrapper .container {
  pointer-events: none;
}
@media (min-width: 992px) {
  .stations-map__map-wrapper .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.stations-map__map-wrapper .map__zoom {
  position: absolute;
  left: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 769px) {
  .stations-map__map-wrapper .map__zoom {
    left: 1rem;
    top: 1rem;
    transform: none;
  }
}
.stations-map .stations-map__search-location .select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}
.stations-map .select2-search--dropdown {
  display: block !important;
  padding: 0 !important;
}
.stations-map .select2-search--dropdown .select2-search__field {
  display: block;
  height: 30px;
  border-radius: 100px;
  padding: 0 0.5rem 0 0 !important;
  margin-top: -31px;
  border: none !important;
  margin-bottom: 10px;
  margin-left: 1.5rem;
  width: 86%;
  background: white;
  -webkit-appearance: none;
}
.stations-map .select2-search--dropdown .select2-search__field::-webkit-textfield-decoration-container {
  background: white;
}
.stations-map .select2-search--dropdown .select2-search__field:focus {
  outline: 0;
}
.stations-map .select2-search--dropdown .select2-search__field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  background-color: white;
  font-family: "ToyotaType", sans-serif;
}
.stations-map .select2-search--dropdown .select2-search__field::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  background-color: white;
  font-family: "ToyotaType", sans-serif;
}
.stations-map .select2-search--dropdown .select2-search__field:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  background-color: white;
  font-family: "ToyotaType", sans-serif;
}
.stations-map .select2-search--dropdown .select2-search__field:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  background-color: white;
  font-family: "ToyotaType", sans-serif;
}
.stations-map .select2-search--dropdown .select2-search__field #editing-view-port {
  background: white;
}
.stations-map .select2-dropdown {
  background-color: transparent !important;
  border: none !important;
  z-index: 2 !important;
}
.stations-map .select2-results {
  background: white;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #002732;
  margin-top: 10px;
  overflow-y: hidden;
}
.stations-map .select2-results__options {
  max-height: 300px !important;
  padding: 0 !important;
}
.stations-map .select2-results__option {
  padding: 6px 15px !important;
  line-height: 1.4;
  font-size: 13px;
}
.stations-map .select2-results__option strong {
  font-size: 14px;
  display: block;
}
.stations-map .select2-results__option--highlighted, .stations-map .select2-results__option[aria-selected=true] {
  color: #002732 !important;
  background-color: #f4f4f4 !important;
}
.stations-map .services_container .select2-results__options {
  box-shadow: 2px 5px 20px 0px rgba(0, 39, 50, 0.1490196078);
}

.map__zoom {
  box-shadow: 0 35px 53px 0 rgba(0, 39, 50, 0.06);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.map__zoom .hr {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.map__zoom-item {
  cursor: pointer;
  outline: none;
  height: 2rem;
  width: 2rem;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 769px) {
  .map__zoom-item {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.map__zoom-item__icon {
  font-size: 2rem;
  line-height: 0;
}
@media (max-width: 769px) {
  .map__zoom-item__icon {
    font-size: 2rem;
  }
}
.map__zoom-item--in .map__zoom-item__icon {
  margin-top: 0.25rem;
}
@media (max-width: 769px) {
  .map__zoom-item--in .map__zoom-item__icon {
    margin-top: 0.25rem;
  }
}
.map__zoom-item--out .map__zoom-item__icon {
  margin-top: -0.25rem;
}
@media (max-width: 769px) {
  .map__zoom-item--out .map__zoom-item__icon {
    margin-top: -0.25rem;
  }
}
.map__info {
  position: relative;
  display: none;
  pointer-events: all;
  box-sizing: border-box;
  border-radius: 6px;
  backdrop-filter: blur(4px);
  background-color: #fff;
  padding: 1.75rem;
}
@media (min-width: 992px) {
  .map__info {
    width: 33.3333333333%;
    box-shadow: 0 35px 53px 0 rgba(0, 39, 50, 0.06);
  }
}
@media (max-width: 769px) {
  .map__info {
    padding-left: 0;
    padding-right: 0;
  }
}
.map__info__close-button {
  position: absolute;
  cursor: pointer;
  top: 0.6rem;
  right: 0.6rem;
  height: 1rem;
  width: 1rem;
  padding: 0;
  border: 0;
  background: none;
}
.map__info__close-button:before, .map__info__close-button:after {
  content: "";
  display: block;
  height: 1px;
  width: 1rem;
  background-color: #e3e9eb;
  top: 50%;
  left: 50%;
}
.map__info__close-button:before {
  transform: rotate(45deg);
}
.map__info__close-button:after {
  transform: rotate(-45deg);
  margin-top: -1px;
}
.map__info__block {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e3e9eb;
}
.map__info__block:first-child {
  padding-top: 0;
}
.map__info__block:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.map__info__block .title {
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.map__info__block .text {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.8;
}
@media (max-width: 577px) {
  .map__info__block .link-icon {
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    font-size: 0.65rem;
    font-weight: 600;
    line-height: 1.2rem;
    text-align: center;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 100px;
    border: 2px solid;
    min-width: 10rem;
    text-decoration: none;
    margin-bottom: 1rem;
  }
}
@media (max-width: 577px) {
  .map__info__block .link-icon:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 577px) {
  .map__info__block .link-icon:hover, .map__info__block .link-icon:focus, .map__info__block .link-icon:active {
    color: #fff;
    background-color: #002732;
    border-color: #002732;
  }
}
@media (max-width: 577px) {
  .map__info__block .link-icon:hover .link-icon__icon, .map__info__block .link-icon:focus .link-icon__icon, .map__info__block .link-icon:active .link-icon__icon {
    filter: invert(100%);
  }
}
@media (max-width: 577px) {
  .map__info__block .link-icon__text {
    text-decoration: none;
  }
}
.map__info .links-list {
  margin: 0;
  margin-top: 0.75rem;
  padding: 0;
  list-style: none;
}
@media (max-width: 577px) {
  .map__info .links-list__item {
    text-align: center;
    margin-bottom: 1rem;
  }
}
@media (max-width: 577px) {
  .map__info .links-list__item:last-child {
    margin-bottom: 0;
  }
}
.map__info .phones {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 576px) {
  .map__info .phones {
    display: flex;
    flex-wrap: wrap;
  }
}
.map__info .phones__item {
  line-height: 1;
}
@media (min-width: 576px) {
  .map__info .phones__item {
    width: 50%;
    flex: 0 0 auto;
  }
}
@media (max-width: 577px) {
  .map__info .phones__item {
    text-align: center;
    margin-bottom: 1rem;
  }
}
@media (max-width: 577px) {
  .map__info .phones__item:last-child {
    margin-bottom: 0;
  }
}
.map__info__title {
  text-decoration: none;
  color: inherit;
}
.map__info__title__text {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  vertical-align: middle;
}
.map__info__title:before {
  content: url("../img/icons/www.png");
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  margin-top: 0.125rem;
}
.map__info .services {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  list-style: none;
}
.map__info .services__item {
  position: relative;
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: #9b9b9b;
}
.map__info .services__item:last-of-type {
  margin-bottom: 0;
}
.map__info .services__item:before {
  content: "-";
  position: absolute;
  left: -0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.services_container {
  width: 100%;
}
@media (min-width: 992px) {
  .services_container {
    max-width: 455px;
  }
}
.services_container #kinto_station_services + span {
  width: 100% !important;
}
@media (min-width: 992px) {
  .services_container #kinto_station_services + span {
    margin-top: 0;
  }
}
.services_container #kinto_station_services + span .select2-selection.select2-selection--single {
  border: none;
  border-bottom: 1px solid #EBECED;
}
.services_container #kinto_station_services + span .select2-selection__rendered {
  font-family: "ToyotaType";
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  color: #0A2C3A;
}
.services_container .select2-selection__placeholder {
  color: #0a2c3a !important;
}

#select2-kinto_station_services-results {
  box-shadow: 2px 5px 20px rgba(0, 39, 50, 0.15);
  border-radius: 0px 0px 6px 6px;
}
#select2-kinto_station_services-results li {
  font-family: "ToyotaType";
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
  color: #0a2c3a;
  padding: 19px 24px;
  border-bottom: 1px solid #ebf4f6;
}
#select2-kinto_station_services-results li.select2-results__option--highlighted {
  background: #ebf4f6;
}

.custom-select__trigger {
  cursor: pointer;
  position: relative;
  font-size: 30px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 2rem;
}
.custom-select__trigger:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 18px;
  margin-left: 0.75rem;
  background: no-repeat url("../img/icons/chevron-navy.svg") center center;
  background-size: contain;
}
.custom-select__options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #002732;
  border-bottom: none;
  box-shadow: 0 35px 55px 0 rgba(0, 0, 0, 0.1);
  background-color: #e3e9eb;
  max-height: 15rem;
  display: flex;
  transition: all 0.4s ease-in-out;
  margin-top: 0.5rem;
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
}
.custom-select__options .container {
  overflow: auto;
}
.custom-select__options-option {
  position: relative;
  display: block;
  text-decoration: none;
  color: #738184;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: -0.56px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.custom-select__options-option:hover, .custom-select__options-option.selection {
  color: #002732;
}
.custom-select.opened .custom-select__options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
@media (max-width: 577px) {
  .custom-select {
    font-size: 25px;
    font-size: 1.25rem;
  }
  .custom-select.--dropdown:after {
    width: 20px;
    height: 15px;
  }
}

.functions-container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.functions-container h1 {
  font-family: "ToyotaType";
  font-size: 50px;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 55px;
  color: #00708d;
  text-align: center;
}
.functions-container.dark-theme {
  background: linear-gradient(88.16deg, #002732 1.91%, #00708D 100.88%);
  color: #FFFFFF;
}
.functions-container.dark-theme h1 {
  color: #FFFFFF;
}
@media (min-width: 992px) {
  .functions-container.dark-theme {
    flex-direction: row;
    align-items: initial;
  }
  .functions-container.dark-theme .function-item::before, .functions-container.dark-theme .function-item::after {
    border-bottom: 2px dashed #D4D7D9 !important;
  }
}
.functions-container .function-items-container {
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 40px;
}
@media (min-width: 992px) {
  .functions-container .function-items-container {
    flex-direction: row;
    align-items: initial;
  }
  .functions-container .function-items-container .function-item {
    position: relative;
  }
  .functions-container .function-items-container .function-item img {
    z-index: 2;
  }
  .functions-container .function-items-container .function-item::before, .functions-container .function-items-container .function-item::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 50px;
    border-bottom: 2px dashed #d4d7d9;
  }
  .functions-container .function-items-container .function-item::before {
    left: -14px;
  }
  .functions-container .function-items-container .function-item::after {
    right: -14px;
  }
  .functions-container .function-items-container .function-item:first-of-type::before {
    display: none;
  }
  .functions-container .function-items-container .function-item:last-of-type::after {
    display: none;
  }
}
.functions-container .function-items-container .function-item {
  width: 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  text-align: center;
}
.functions-container .function-items-container .function-item h3 {
  font-family: "ToyotaType";
  font-size: 20px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
}
.functions-container .function-items-container .function-item p {
  font-size: 16px;
  font-size: 0.8rem;
  line-height: 23px;
  font-family: "Nunito";
  font-weight: 400;
}
@media (max-width: 577px) {
  .functions-container {
    margin-left: 10px;
    margin-right: 10px;
  }
  .functions-container.dark-theme {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .functions-container h1 {
    font-size: 30px;
    font-size: 1.5rem;
    line-height: 35px;
  }
}

.sevice-detail-container {
  margin-top: 100px;
  margin-bottom: 100px;
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.sevice-detail-container .left-section {
  margin-left: 20%;
}
.sevice-detail-container .left-section h2 {
  font-size: 50px;
  font-size: 2.5rem;
  font-family: "ToyotaType";
  font-weight: 400;
  line-height: 55px;
  color: #00708d;
  max-width: 475px;
  margin-bottom: 40px;
}
.sevice-detail-container .left-section h2.small-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 40px;
}
.sevice-detail-container .left-section .details-list ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.sevice-detail-container .left-section .details-list ul li {
  display: flex;
}
.sevice-detail-container .left-section .details-list ul li .check {
  background: url("../img/icons/check.svg") no-repeat center;
  height: 30px;
  padding-top: 7px;
  padding-left: 25px;
}
.sevice-detail-container .left-section .details-list ul li p {
  font-size: 0.9rem;
  margin-left: 10px;
}
.sevice-detail-container .left-section .details-list ul li p strong {
  font-weight: 400;
}
.sevice-detail-container .right-section {
  position: absolute;
  right: -150px;
  top: 100px;
}
@media (max-width: 577px) {
  .sevice-detail-container {
    flex-direction: column;
    position: initial;
    padding-right: 1rem;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sevice-detail-container .left-section {
    margin-left: 0;
  }
  .sevice-detail-container .left-section h2 {
    font-size: 30px;
    font-size: 1.5rem;
    line-height: 30px;
  }
  .sevice-detail-container .right-section {
    position: initial;
    left: 0px;
    margin-top: 40px;
  }
  .sevice-detail-container .right-section .image-container img {
    width: 100%;
    position: relative;
    left: 30px;
  }
}

.service-plan-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.partners {
  margin-bottom: 100px;
}
.partners__title {
  font-size: 50px;
  font-size: 2.5rem;
  color: #00708d;
  margin-bottom: 50px;
}
@media (max-width: 577px) {
  .partners__title {
    font-size: 30px;
    font-size: 1.5rem;
  }
}
.partners__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 577px) {
  .partners__container {
    row-gap: 14px;
  }
}
.partners__container__item {
  flex: 1 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners__container__item img {
  max-width: 188px;
}
@media (max-width: 577px) {
  .partners__container__item {
    width: 165px;
  }
}
@media (max-width: 577px) {
  .partners {
    margin-bottom: 80px;
  }
}

.hero-full .title1 {
  max-width: 540px;
}

.sect_modelos .title1 {
  max-width: 960px;
  margin: 0 auto 5rem;
  line-height: 1.2em;
}
.sect_modelos .extra-background {
  background: #ebf4f6;
  background: -moz-linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51%, #ebf4f6 51%, #e3e9eb 100%);
  background: -webkit-linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51%, #ebf4f6 51%, #e3e9eb 100%);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 51%, #ebf4f6 51%, #e3e9eb 100%);
}
@media (max-width: 769px) {
  .sect_modelos .extra-background {
    background: #ebf4f6;
    background: -moz-linear-gradient(180deg, #ebf4f6 0%, #e3e9eb 100%);
    background: -webkit-linear-gradient(180deg, #ebf4f6 0%, #e3e9eb 100%);
    background: linear-gradient(180deg, #ebf4f6 0%, #e3e9eb 100%);
  }
}

.sect_acerca .hero-full .title1 {
  max-width: 960px;
}
.sect_acerca .hero-full b {
  font-weight: 600;
}
.sect_acerca .intro {
  padding-bottom: 3rem;
  background: linear-gradient(135.07deg, #FFFFFF 0.13%, #EBF4F6 71.91%, #E3E9EB 100%);
  max-width: unset;
  margin: 0;
  padding-left: 10rem;
  padding-right: 10rem;
}
.sect_acerca .subhead {
  font-size: 26px;
  font-size: 1.3rem;
  font-family: "ToyotaType";
  font-weight: 600;
  line-height: 1.4;
  display: block;
  max-width: 400px;
  margin: 0 auto 3rem;
}
.sect_acerca .teaser {
  font-size: 18px;
  font-size: 0.9rem;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.sect_acerca .teaser b {
  font-weight: 600;
}
.sect_acerca .row {
  display: grid;
  grid-template-columns: 490px 1fr;
  grid-gap: 2rem;
}
.sect_acerca .product-tree {
  width: 100%;
  margin-bottom: 3rem;
}
@media (max-width: 769px) {
  .sect_acerca .subhead {
    margin-bottom: 0rem;
  }
  .sect_acerca .row {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 769px) {
  .sect_acerca .teaser {
    font-size: 20px;
    font-size: 1rem;
  }
}
@media (max-width: 577px) {
  .sect_acerca .intro {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sect_acerca .teaser p {
    font-size: 18px;
    font-size: 0.9rem;
  }
}

.sect_ayuda b {
  font-weight: 600;
}
.sect_ayuda .questions-container {
  display: none;
}
.sect_ayuda .questions-container.show {
  display: block;
}
.sect_ayuda .header-title {
  margin-bottom: 60px;
}
.sect_ayuda .contact-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
  grid-template-areas: "title form" "info form";
}
.sect_ayuda .contact-row__title {
  grid-area: title;
  padding-right: 4rem;
}
.sect_ayuda .contact-row__info {
  grid-area: info;
  padding-right: 4rem;
}
.sect_ayuda .contact-row__info-schedule {
  font-size: 14px;
  font-size: 0.7rem;
  line-height: 1.71;
  margin-bottom: 1.5rem;
}
.sect_ayuda .contact-row__info-schedule h5 {
  font-size: 20px;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.27;
}
.sect_ayuda .contact-row__info-schedule p {
  margin: 1em 0;
}
.sect_ayuda .contact-row__info-networks__title {
  margin-bottom: 1.5rem;
}
.sect_ayuda .contact-row__info-networks__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.sect_ayuda .contact-row__info-networks__list-item {
  display: inline-block;
  margin-right: 1.5rem;
}
.sect_ayuda .contact-row__info-networks__list-item:last-child {
  margin-right: 0;
}
.sect_ayuda .contact-row__info-networks__list-item__icon .inner {
  fill: #fff;
  transition: fill 0.2s ease;
}
.sect_ayuda .contact-row__info-networks__list-item__icon .outer {
  fill: #00708d;
  transition: fill 0.2s ease;
}
.sect_ayuda .contact-row__info-networks__list-item__icon:hover .inner {
  fill: #00708d;
}
.sect_ayuda .contact-row__info-networks__list-item__icon:hover .outer {
  fill: #fff;
}
.sect_ayuda .contact-row__form {
  grid-area: form;
  border-left: 1px solid #002732;
  padding-left: 4rem;
}
@media (max-width: 769px) {
  .sect_ayuda .contact-row {
    grid-template-areas: "title title" "form form" "info info";
  }
  .sect_ayuda .contact-row__form {
    border-left: 0;
    padding-left: 0;
    margin-bottom: 2rem;
  }
}
@media (max-width: 577px) {
  .sect_ayuda .accordion-wrapper .title3 {
    margin-bottom: 1rem;
  }
  .sect_ayuda .contact-row__title .title3 {
    margin-bottom: 1rem;
  }
  .sect_ayuda .contact-row__info-schedule {
    display: none;
  }
}

.cotizador h4 {
  font-size: 25px;
  line-height: 32px;
  color: #002732;
  margin: 15px 0;
  font-weight: 600;
}
.cotizador h5 {
  font-size: 16px;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.27;
}
.cotizador .title1 {
  margin-bottom: 20px;
}
.cotizador .button-group-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cotizador .button-group-toggle button {
  background: #fff;
  padding: 12px 15px;
  border: 1.5px solid #00708d;
  border-radius: 6px;
  font-size: 16px;
  color: #002732;
  font-weight: 600;
  margin-right: 15px;
  cursor: pointer;
  transition: background 100ms ease-in-out;
}
.cotizador .button-group-toggle button:last-child {
  margin-right: 0;
}
.cotizador .button-group-toggle button.active {
  background-color: #002732;
  color: #fff;
}
.cotizador .button-group-toggle button:disabled {
  pointer-events: none;
  opacity: 0.5;
}
.cotizador .downpayment_range {
  width: 100%;
  max-width: 470px;
  display: flex;
  flex-direction: column;
}
.cotizador .downpayment_range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  max-width: 450px;
  height: 8px;
  border-radius: 4px;
  background: #d4d7d9;
  background-image: linear-gradient(#00708d, #00708d);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.cotizador .downpayment_range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1019607843);
  transition: background 0.3s ease-in-out;
  border: 1px solid #ddd;
}
.cotizador .downpayment_range input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.cotizador .downpayment_range .tickmarks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.cotizador .downpayment_range .tickmarks span {
  color: #002732;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  left: -3px;
}

@media print {
  .print {
    display: block;
  }
  .no-print {
    display: none !important;
  }
}
.time-and-model {
  margin-bottom: 50px;
}
.time-and-model .title3 {
  font-size: 25px;
  font-size: 1.25rem;
  margin-bottom: 15px;
}
.time-and-model .left-section {
  margin-bottom: 40px;
}
.time-and-model .left-section .form-group-cotizador {
  margin-bottom: 25px;
}
.time-and-model .left-section .form-group-cotizador span {
  font-size: 10px;
  font-size: 0.5rem;
  position: absolute;
  bottom: -15px;
}

.subtitle {
  color: #00708d;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.copy p {
  margin-bottom: 50px;
}
.copy p a {
  text-decoration: underline;
  color: #002732;
}

.chev-down {
  background: no-repeat url("../img/icons/chevron-navy.svg") center center;
}

/*Radiobutton*/
.custom-radio {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .custom-radio {
    flex-direction: column;
    padding-left: 25px;
  }
}

.custom-radio div {
  font-weight: 300;
}
@media (max-width: 769px) {
  .custom-radio div {
    margin-bottom: 5px;
  }
}

.radio-custom {
  opacity: 0;
  position: absolute;
}

.radio-custom-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.radio-custom-label:before {
  content: "";
  border: 2px solid #00dbeb;
  display: inline-block;
  vertical-align: middle;
  padding: 2px;
  margin-right: 10px;
  border-radius: 50%;
}
.radio-custom-label.small {
  height: 28px;
}
.radio-custom-label.small:before {
  width: 22px;
  height: 22px;
}
.radio-custom-label.large {
  height: 34px;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.27;
}
.radio-custom-label.large:before {
  margin-right: 15px;
  width: 30px;
  height: 30px;
}
@media (max-width: 769px) {
  .radio-custom-label.large:before {
    margin-right: 5px;
  }
}

.radio-custom:checked + .radio-custom-label:before {
  background: linear-gradient(45deg, #00dbeb 0%, #FFF 80%);
}

.radio-custom:checked + .radio-custom-label:after {
  content: "";
  display: block;
  position: absolute;
  background: #002732;
  border-radius: 50%;
}
.radio-custom:checked + .radio-custom-label.small:after {
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
  left: 5px;
}
.radio-custom:checked + .radio-custom-label.large:after {
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  left: 7px;
}

.form-group-cotizador {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 769px) {
  .form-group-cotizador {
    margin-bottom: 25px;
  }
}

.form-control {
  font-family: "ToyotaType", sans-serif;
  display: block;
  padding: 0.1rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.5;
  color: #002732;
  background-color: #fff;
  background-clip: padding-box;
  border: 1.5px solid #00dbeb;
  border-radius: 0.3rem;
  outline: none;
}
.form-control.select-time {
  width: 40%;
  margin-right: 20px;
}
@media (max-width: 769px) {
  .form-control.select-time {
    width: 65%;
  }
}
.form-control.time-input {
  width: 80px;
}
@media (max-width: 769px) {
  .form-control.time-input {
    width: 60px;
  }
}
.form-control.material-select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  font-size: 1rem;
  width: 100%;
  border-bottom: 1px solid #d4d7d9;
  padding-bottom: 0.3rem;
  padding-left: 0;
  cursor: pointer;
  background: no-repeat url("../img/icons/chevron-navy.svg") center;
  background-size: 14px;
  background-position-x: 99%;
}
.form-control.chev {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat url("../img/icons/chevron-navy.svg") center;
  background-size: 14px;
  background-position-x: 95%;
  background-position-y: 60%;
}

.car-list {
  background-color: #fff;
  position: relative;
}
.car-list ul {
  overflow-y: auto;
  display: none;
  width: 100%;
  max-height: 260px;
  box-shadow: 0px 5px 15px #d4d7d9;
  border-radius: 0 0 5px 5px;
  position: absolute;
  z-index: 200;
  top: 39px;
  background-color: #fff;
}
.car-list ul.active {
  display: block;
}
.car-list ul li {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  align-items: center;
  border-bottom: 1px solid #e3e9eb;
  padding: 5px 10px;
  cursor: pointer;
}
.car-list ul li:hover {
  background-color: #e3e9eb;
}
.car-list ul li:last-child {
  border-bottom: none;
}
.car-list ul li .thumb-select-car {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 55px;
}
.car-list ul li .thumb-select-car img {
  width: 100%;
}
.car-list ul li small {
  display: block;
  font-size: 10px;
  font-size: 0.5rem;
  font-weight: normal;
}
.car-list ul li h4 {
  font-size: 20px;
  font-size: 1rem;
  margin: 0;
}
.car-list ul li.is-modal {
  grid-template-columns: 1fr;
  padding: 10px 20px;
}
.car-list ul li.is-modal .thumb-select-car {
  display: none;
}

select {
  cursor: pointer;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
}
@media (max-width: 769px) {
  .grid-2 {
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
}

.print-compare .print-icon {
  cursor: pointer;
}
@media (min-width: 769px) {
  .print-compare {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
}

.box {
  border-radius: 0.3rem;
  padding: 10px 40px;
  width: 100%;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 15px;
}
.box.info {
  background-color: #a4f9ff;
}
.box.info a:hover {
  cursor: pointer;
  color: #00708d;
}

.price-card .list-price p {
  font-weight: normal;
  display: inline-block;
  min-width: 75px;
}
.price-card .list-price small {
  font-size: 10px;
  font-size: 0.5rem;
  color: #002732;
  font-weight: normal;
}
.price-card .franchise {
  display: block;
  font-size: 10px;
  font-size: 0.5rem;
  color: #738184;
  margin-top: 0.5rem;
}

.time-container {
  border-right: 1px solid #d4d7d9;
  padding-right: 40px;
  height: 100%;
}
@media (max-width: 769px) {
  .time-container {
    border-right: none;
    padding-right: 0;
    padding-bottom: 50px;
    border-bottom: 1px solid #d4d7d9;
  }
}
.time-container .instructions ol {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.time-container .instructions ol li {
  display: flex;
}
.time-container .instructions ol li .check {
  background: url("../img/icons/check.svg") no-repeat center;
  height: 30px;
  padding-top: 7px;
  padding-left: 25px;
}
.time-container .instructions ol li p {
  font-size: 0.9rem;
  margin-left: 10px;
}
.time-container .instructions ol li p strong {
  font-weight: 400;
}

.cercania p {
  font-size: 0.8rem;
}
.cercania form {
  margin: 5px 0 10px 0;
}
.cercania form label {
  font-size: 0.8rem;
}
@media (max-width: 769px) {
  .cercania {
    padding-left: 25px;
  }
}

.otra-sucursal p {
  font-size: 16px;
  font-size: 0.8rem;
}
.otra-sucursal p a {
  text-decoration: underline;
  color: #002732;
}

.necesitas-que {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 10px;
  padding: 0 25px;
}
@media (max-width: 769px) {
  .necesitas-que {
    padding-right: 5px;
  }
}
@media (max-width: 769px) {
  .necesitas-que span {
    margin-bottom: 10px;
  }
}
.necesitas-que select {
  width: 70%;
}
@media (max-width: 769px) {
  .necesitas-que select {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .right-section {
    padding-left: 40px;
  }
}
@media (max-width: 1100px) {
  .right-section {
    padding-left: 20px;
  }
}
@media (max-width: 769px) {
  .right-section {
    padding-left: 0;
  }
}

.model-image {
  height: 180px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.model-image img {
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1100px) {
  .model-image {
    height: auto;
    width: 100%;
  }
}

.add-extra {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
@media (max-width: 769px) {
  .add-extra .button {
    width: 100%;
  }
}

.model-actions {
  text-align: center;
  margin-bottom: 41px;
}
.model-actions .spec-link {
  margin-top: 12px;
}
.model-actions .spec-link a {
  font-size: 0.8rem;
  margin: 10px 0;
  color: #002732;
  cursor: pointer;
  font-size: 16px;
}
.model-actions .spec-link a:hover {
  text-decoration: underline;
}
.model-actions span {
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;
  color: #002732;
}
@media (max-width: 550px) {
  .model-actions {
    margin-bottom: 30px;
  }
}

.model-details {
  display: flex;
  justify-content: center;
}
.model-details .detail {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  padding-left: 10px;
  padding-right: 10px;
}
.model-details .detail .desde {
  grid-column: 1/-1;
  grid-row: 1;
  font-size: 12px;
}
.model-details .detail .price {
  grid-column: 1;
  grid-row: 2;
  font-size: 20px;
  line-height: 20px;
  margin-right: 5px;
}
.model-details .detail .months {
  grid-column: 2;
  grid-row: 2;
  font-size: 12px;
  line-height: 0.8;
}
.model-details .line {
  background-color: #d4d7d9;
  width: 1px;
}
@media (max-width: 1150px) {
  .model-details .detail {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1020px) {
  .model-details {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
@media (max-width: 550px) {
  .model-details {
    flex-direction: column;
  }
  .model-details .line, .model-details .desde {
    display: none;
  }
  .model-details .detail {
    display: flex;
    margin: auto;
    margin-bottom: 4px;
    align-items: center;
  }
  .model-details .detail br {
    display: none;
  }
}

.selected-car-model {
  margin-top: 1.5rem;
}
.selected-car-model .compare-models-link i img {
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
}
.selected-car-model.disabled a, .selected-car-model.disabled span {
  color: #d4d7d9;
  pointer-events: none;
}
.selected-car-model.disabled .compare-models-link i img {
  filter: invert(95%) sepia(10%) saturate(59%) hue-rotate(161deg) brightness(94%) contrast(82%);
}

.extras {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0;
}
.extras.disabled .button {
  color: #d4d7d9;
  border-color: #d4d7d9;
  pointer-events: none;
}

.modal.show {
  display: flex;
}

.compare-modal .modal .modal-box {
  margin: 0 auto;
}
.compare-modal .modal .modal-box .modal-header {
  min-height: 150px;
  padding: 1.5rem 3rem;
}
.compare-modal .modal .modal-box .modal-header h3 {
  font-size: 30pxpx;
  font-size: 1.5pxrem;
}
.compare-modal .modal .modal-box .modal-header .modal-close {
  top: calc(50% - 45px);
  font-weight: 600;
}
@media (max-width: 769px) {
  .compare-modal .modal .modal-box .modal-header .modal-close {
    right: 1rem;
  }
}
@media (max-width: 769px) {
  .compare-modal .modal .modal-box .modal-header {
    padding: 30px 16px;
  }
}
.compare-modal .modal .modal-box .modal-content {
  padding: 1.5rem 3rem;
}
.compare-modal .modal .modal-box .modal-content ul {
  margin: 0;
}
.compare-modal .modal .modal-box .modal-content .modal-body {
  margin-top: -85px;
  padding: 0 30px 50px 30px;
}
@media (max-width: 769px) {
  .compare-modal .modal .modal-box .modal-content .modal-body {
    padding: 0 0 30px 0;
    margin-top: -75px;
  }
}
.compare-modal .modal .modal-box .modal-content .modal-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  border-top: 1px solid #d4d7d9;
  background-color: transparent;
  padding: 25px 0;
}
@media (max-width: 769px) {
  .compare-modal .modal .modal-box .modal-content .modal-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .compare-modal .modal .modal-box .modal-content .modal-footer .button {
    margin: 15px 0 20px;
  }
}
@media (max-width: 769px) {
  .compare-modal .modal .modal-box .modal-content {
    padding: 30px 15px 20px 15px;
  }
}
.compare-modal .models-to-compare-grid .regular {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px;
}
@media (max-width: 769px) {
  .compare-modal .models-to-compare-grid {
    display: block;
  }
  .compare-modal .models-to-compare-grid .slick-track .model-to-compare {
    padding: 0 40px;
  }
}
.compare-modal .slick-slider .slick-arrow {
  display: block !important;
  background: none;
  height: 90px;
}
.compare-modal .slick-slider .slick-arrow:after {
  background-image: url("../img/icons/chevron-navy.svg");
}
.compare-modal .slick-slider .slick-arrow.slick-next {
  right: -15px;
}
.compare-modal .slick-slider .slick-arrow.slick-prev {
  left: -15px;
}

.titleBlue {
  font-size: 25px;
  font-size: 1.25rem;
  color: #00708d;
}

.hr2 {
  height: 2px;
  margin: 30px 0;
  background-color: #ebf4f6;
}
@media (max-width: 769px) {
  .hr2 {
    margin: 40px 0;
  }
}

.adicionales-modal .modal .modal-box .modal-header {
  padding: 30px 65px;
}
.adicionales-modal .modal .modal-box .modal-header h3 {
  font-size: 30px;
  font-size: 1.5rem;
}
@media (max-width: 769px) {
  .adicionales-modal .modal .modal-box .modal-header h3 {
    font-size: 25px;
    font-size: 1.25rem;
  }
}
.adicionales-modal .modal .modal-box .modal-header .modal-close {
  font-weight: 600;
}
@media (max-width: 769px) {
  .adicionales-modal .modal .modal-box .modal-header {
    padding: 30px 16px;
    border-radius: 0;
  }
}
.adicionales-modal .modal .modal-box .modal-content {
  padding: 30px 65px 50px 65px;
}
@media (max-width: 769px) {
  .adicionales-modal .modal .modal-box .modal-content {
    padding: 30px 15px 50px 15px;
  }
}
.adicionales-modal .adicionales-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 769px) {
  .adicionales-modal .adicionales-container {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
@media (min-width: 769px) {
  .adicionales-modal .adicionales-container .adicionales-card:nth-child(even) {
    padding-left: 80px;
    padding-right: 30px;
  }
  .adicionales-modal .adicionales-container .adicionales-card:nth-child(odd) {
    padding-right: 80px;
    padding-left: 30px;
    border-right: 1px solid #d4d7d9;
  }
}
.adicionales-modal .adicionales-container p {
  font-size: 14px;
  font-size: 0.7rem;
  line-height: 1.71;
}
.adicionales-modal .adicionales-container .adicionales-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 20px;
}
.adicionales-modal .adicionales-container .adicionales-footer .title4 {
  margin: 0;
}
.adicionales-modal .adicionales-container .adicionales-footer .title4 span {
  font-weight: 500;
}
.adicionales-modal .adicionales-container .adicionales-footer .button {
  min-width: 150px;
  border: 2px solid;
}

.modal .modal-box .modal-header {
  padding: 30px 65px;
}
.modal .modal-box .modal-header .modal-close {
  font-weight: 600;
}
@media (max-width: 769px) {
  .modal .modal-box .modal-header {
    padding: 30px 15px;
    border-radius: 0;
  }
}
.modal .modal-box .modal-content {
  padding: 30px 65px 50px 65px;
}
@media (max-width: 769px) {
  .modal .modal-box .modal-content {
    padding: 30px 15px 50px 15px;
  }
}
.modal .modal-box .contact-container .title4 {
  margin: 0 0 20px;
}
.modal .modal-box .contact-container p {
  font-size: 14px;
  font-size: 0.7rem;
  margin-bottom: 20px;
  line-height: 1.71;
}
.modal .modal-box .contact-container .info-section {
  position: relative;
  padding-right: 45px;
}
.modal .modal-box .contact-container .contact-section .form__row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (min-width: 768px) {
  .modal .modal-box .contact-container .contact-section {
    padding-left: 45px;
  }
}
.modal .modal-box .contact-container .social-media {
  position: absolute;
  bottom: 0;
}
@media (max-width: 577px) {
  .modal .modal-box .contact-container .social-media {
    position: initial;
  }
}
.modal .modal-box .contact-container .social-media .subtitle {
  font-size: 18px;
  font-size: 0.9rem;
  color: #002732;
  margin-bottom: 30px;
}
.modal .modal-box .contact-container .social-media .social-media-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -ms-flex-pack: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.modal .modal-box .contact-container .social-media .social-media-list li {
  display: inline-block;
  margin-right: 1.5rem;
}
.modal .modal-box .contact-container .social-media .social-media-list li a svg .inner {
  fill: #fff;
  transition: fill 0.2s ease;
}
.modal .modal-box .contact-container .social-media .social-media-list li a svg .outer {
  fill: #00708d;
  transition: fill 0.2s ease;
}
.modal .modal-box .contact-container .social-media .social-media-list li a svg:hover .inner {
  fill: #00708d;
}
.modal .modal-box .contact-container .social-media .social-media-list li a svg:hover .outer {
  fill: #fff;
}
@media (max-width: 577px) {
  .modal .modal-box .contact-container .social-media {
    display: none;
  }
}
@media (max-width: 577px) {
  .modal .modal-box .contact-container .social-media.social-media-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .modal .modal-box .contact-container .social-media.social-media-footer .social-media-list {
    justify-content: center;
    column-gap: 20px;
  }
  .modal .modal-box .contact-container .social-media.social-media-footer .social-media-list li {
    margin: 0px;
  }
}

.contact-confirmation-modal .modal-box .modal-header {
  padding: 30px 65px;
  background-color: #ebf4f6;
}
.contact-confirmation-modal .modal-box .modal-header .modal-close {
  font-weight: 600;
}
@media (max-width: 769px) {
  .contact-confirmation-modal .modal-box .modal-header {
    padding: 30px 15px;
    border-radius: 0;
  }
}
.contact-confirmation-modal .modal-box .modal-content {
  background-color: #ebf4f6;
}
@media (min-width: 769px) {
  .contact-confirmation-modal .modal-box .modal-content {
    padding: 80px 65px 80px 65px;
    border-radius: 6px 6px;
  }
}
.contact-confirmation-modal .modal-box .modal-content .modal-body .contact-confirmation-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.contact-confirmation-modal .modal-box .modal-content .modal-body .contact-confirmation-container .title {
  margin: 20px 0 15px 0;
}

.door-to-door-modal .modal-box .modal-header {
  padding: 30px 65px;
}
.door-to-door-modal .modal-box .modal-header .modal-close {
  font-weight: 600;
}
@media (max-width: 769px) {
  .door-to-door-modal .modal-box .modal-header {
    padding: 30px 15px;
    border-radius: 0;
  }
}
.door-to-door-modal .modal-box .modal-content {
  padding: 30px 65px 50px 65px;
}
@media (max-width: 769px) {
  .door-to-door-modal .modal-box .modal-content {
    padding: 30px 15px 50px 15px;
  }
}
.door-to-door-modal .modal-box .modal-content .modal-body ul li {
  font-size: 18px;
  font-size: 0.9rem;
  line-height: 1.56;
  margin-bottom: 20px;
  list-style: none;
  text-align: left;
}
.door-to-door-modal .modal-box .modal-content .modal-body ul li:last-child {
  margin-bottom: 0;
}
.door-to-door-modal .modal-box.large {
  max-width: 930px;
}

.model-to-compare .material-select {
  border-bottom: 1px solid #002732;
}
.model-to-compare .material-select.bold {
  font-weight: 600;
}
.model-to-compare .model-image {
  height: 120px;
}
.model-to-compare .model-price-list ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #d4d7d9;
  list-style: none !important;
}
.model-to-compare .model-price-list ul li:last-child {
  border-bottom: 1px solid #002732;
}
.model-to-compare .total-price-box {
  margin-top: 1rem;
}
.model-to-compare .total-price-box p {
  font-weight: 600;
}
.model-to-compare .total-price-box p span {
  font-size: 30px;
  font-size: 1.5rem;
}
.model-to-compare.empty .model-price-list {
  color: #d4d7d9;
  font-weight: 300;
}
.model-to-compare.empty .total-price-box {
  color: #d4d7d9;
}
.model-to-compare .empty-price {
  letter-spacing: -5px;
  margin-right: 5px;
}

.footer-cotizador {
  height: 100%;
  background-color: #fff;
}
@media (max-width: 769px) {
  .footer-cotizador {
    height: 100%;
  }
}
.footer-cotizador .footer-body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 30px 0 15px 0;
}
.footer-cotizador .footer-body .title3 {
  margin-bottom: 0;
}
@media (max-width: 769px) {
  .footer-cotizador .footer-body .title3 {
    font-size: 18px;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}
.footer-cotizador .footer-body .total-price {
  text-align: right;
}
@media (max-width: 769px) {
  .footer-cotizador .footer-body .total-price {
    text-align: left;
  }
  .footer-cotizador .footer-body .total-price .title3 {
    margin-bottom: 0;
  }
}
.footer-cotizador .footer-body div a {
  text-decoration: none;
  color: #002732;
}
.footer-cotizador .footer-body div a span {
  font-size: 14px;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;
}
.footer-cotizador .footer-body div a span img {
  width: 10px;
  height: 10px;
}
.footer-cotizador .footer-body div a.active span img {
  transform: rotate(180deg);
}
.footer-cotizador .footer-body div p {
  margin-top: 5px;
  font-size: 16px;
  font-size: 0.8rem;
}
.footer-cotizador .footer-bottom .container .options {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  border-top: 2px solid #e0e5e7;
  padding: 15px 0;
  gap: 16px;
}
.footer-cotizador .footer-bottom .container .options .button {
  padding-bottom: 8px;
}
@media (max-width: 769px) {
  .footer-cotizador .footer-bottom .container .options {
    text-align: left;
    align-items: center;
    flex-direction: column-reverse;
    border-top: none;
    padding: 0;
    width: 100%;
    padding-bottom: 20px;
  }
  .footer-cotizador .footer-bottom .container .options .button {
    padding: 5px 25px 8px 25px;
    width: 247px;
  }
}
.footer-cotizador .footer-bottom.active {
  background-color: #fff;
}
.footer-cotizador .footer-bottom.active .container .options {
  border-top: 0;
}
.footer-cotizador .selected-model.disabled, .footer-cotizador .selected-days.disabled {
  color: #a6acb0;
}
.footer-cotizador .resumen {
  background-color: #fff;
  display: none;
}
.footer-cotizador .resumen.active {
  display: block;
}
.footer-cotizador .resumen .title-resumen {
  font-size: 18px;
  font-size: 0.9rem;
}
.footer-cotizador .resumen ul {
  padding-top: 30px;
}
.footer-cotizador .resumen ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  padding: 10px 0;
  border-bottom: 2px solid #ebf4f6;
  color: #1a394e;
}
.footer-cotizador .resumen ul li:first-child {
  color: #002732;
}
.footer-cotizador .resumen ul li .price {
  text-align: right;
}
.footer-cotizador .resumen ul li .description {
  width: 65%;
}
.footer-cotizador .resumen ul li p {
  font-size: 14px;
  font-size: 0.7rem;
}
.footer-cotizador .resumen ul li p small {
  color: #738184;
  font-size: 12px;
  font-size: 0.6rem;
}
.footer-cotizador .resumen .small {
  padding: 30px 0;
  line-height: 1.2;
  border-bottom: 2px solid #e0e5e7;
  font-size: 10px;
  font-size: 0.5rem;
}
.footer-cotizador.disabled .footer-body div a span {
  color: #a4b6bb;
  cursor: default;
}
.footer-cotizador.disabled .footer-body div a span img {
  filter: invert(76%) sepia(20%) saturate(175%) hue-rotate(146deg) brightness(93%) contrast(85%);
}
.footer-cotizador.disabled .footer-bottom .print-compare .print-icon {
  cursor: default;
}
.footer-cotizador.disabled .footer-bottom .print-compare img {
  filter: invert(76%) sepia(20%) saturate(175%) hue-rotate(146deg) brightness(93%) contrast(85%);
}

.cotizador.one_personal .time-container .options {
  margin-top: 15px;
}
.cotizador.one_personal .time-container .options + h3 {
  margin-top: 50px;
}
.cotizador.one_personal .time-container .options + h3:first-of-type {
  margin-top: 0;
}
.cotizador.one_personal .extras {
  display: block;
}
.cotizador.one_personal .extras .list-price {
  text-align: center;
}
.cotizador.one_personal .extras .list-price br {
  display: none;
}
@media (min-width: 768px) {
  .cotizador.one_personal .extras .price-card {
    display: flex;
    justify-content: space-between;
  }
  .cotizador.one_personal .extras .price-card .list-price {
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    display: flex;
    align-items: flex-end;
  }
  .cotizador.one_personal .extras .price-card .list-price .price {
    font-size: 20px;
  }
  .cotizador.one_personal .extras .price-card .list-price br {
    display: initial;
  }
  .cotizador.one_personal .extras .price-card .list-price small {
    display: block;
    max-width: 50px;
    margin-left: 5px;
  }
  .cotizador.one_personal .extras .price-card .list-price:nth-child(2) {
    border-left: 1px solid #d4d7d9;
    border-right: 1px solid #d4d7d9;
    padding: 0 10px;
  }
}
.cotizador.one_personal .resumen .title-resumen.description {
  font-size: 14px;
  font-size: 0.7rem;
}

#cotizador_root {
  position: relative;
}
#cotizador_root #cotizador_overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.sect_legal {
  padding-bottom: 180px;
}
.sect_legal .legal {
  padding-bottom: 30px;
  padding-top: 40px;
}
.sect_legal .legal__title {
  margin-bottom: 25px;
}
.sect_legal b {
  font-weight: bold;
}
.sect_legal ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.sect_legal li {
  list-style: initial !important;
}

.sect_comercial-info .comercial-info {
  padding-bottom: 30px;
  padding-top: 40px;
}
.sect_comercial-info .comercial-info__list {
  padding-bottom: 250px;
}
.sect_comercial-info .comercial-info__title {
  margin-bottom: 25px;
}

.kinto-service .hero-full {
  text-align: center;
}
.kinto-service .hero-full .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}
.kinto-service .hero-full .container .logo-container {
  max-width: 184px;
}
.kinto-service .hero-full__rectangle {
  position: absolute;
  top: 120px;
  left: 0;
  padding: 80px 24px;
  width: fit-content;
  max-width: 40%;
  background-color: #00708D;
  color: white;
  text-align: start;
  font-size: clamp(38px, 5vw, 48px);
  line-height: 1;
}
.kinto-service .hero-full__rectangle b {
  font-weight: 800;
}
.kinto-service .service-description {
  padding-bottom: 3rem;
  background: linear-gradient(88.16deg, #002732 1.91%, #00708D 100.88%);
  max-width: unset;
  margin: 0;
  padding-left: 10rem;
  padding-right: 10rem;
}
.kinto-service .service-description.light-theme {
  background: none;
  color: #00708D;
}
.kinto-service .service-description.light-theme .subhead {
  color: #002732;
}
.kinto-service .service-description.light-theme .teaser {
  color: #002732;
}
.kinto-service .service-description .row {
  display: flex;
  justify-content: center;
  column-gap: 60px;
}
.kinto-service .service-description .subhead {
  font-size: 20px;
  font-size: 1rem;
  font-family: "ToyotaType";
  font-weight: 600;
  line-height: 1.4;
  display: block;
  max-width: 435px;
  color: #fff;
}
.kinto-service .service-description .teaser {
  font-size: 18px;
  font-size: 0.9rem;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: #fff;
  max-width: 635px;
}
.kinto-service .service-description .teaser b {
  font-weight: 600;
}
.kinto-service .cars-list {
  margin-bottom: 80px;
}
.kinto-service .cars-list .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 55px;
}
.kinto-service .cars-list .title-container h2 {
  margin-bottom: 0px;
}
.kinto-service .cars-list .models-grid {
  padding-bottom: 0;
  border: none;
}
.kinto-service .cars-list .models-grid__item-data__buy {
  margin-top: 20px;
}
.kinto-service .cars-list .disclaimer {
  line-height: 0.6;
  margin-top: 20px;
}
.kinto-service .cars-list .disclaimer small {
  color: #738184;
  font-size: 0.5rem;
}
.kinto-service .download-app-banner {
  background-color: #F5F5F5;
}
.kinto-service .download-app-banner .container {
  display: flex;
  justify-content: space-around;
  padding-top: 45px;
}
.kinto-service .download-app-banner .container .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kinto-service .download-app-banner .container .content-container .text-container {
  margin-bottom: 40px;
}
.kinto-service .download-app-banner .container .content-container .text-container h1 {
  font-size: clamp(32px, 4vw, 50px);
  margin-bottom: 24px;
}
.kinto-service .download-app-banner .container .content-container .text-container p {
  font-size: clamp(16px, 2vw, 20px);
  color: #738184;
  max-width: 410px;
}
.kinto-service .download-app-banner .container .content-container .cta-container {
  display: flex;
  gap: clamp(14px, 2vw, 25px);
}
.kinto-service .download-app-banner .container .content-container .cta-container a {
  padding: 0;
}
.kinto-service .download-app-banner .container .content-container .cta-container a img {
  max-width: 220px;
}
.kinto-service .download-app-banner .container .image-container {
  max-width: 50%;
}
.kinto-service .download-app-banner .container .image-container img {
  width: 100%;
}
@media (max-width: 577px) {
  .kinto-service .hero-full__rectangle {
    padding: 40px 24px;
    top: auto;
    bottom: 0;
    max-width: 100%;
    width: 100%;
  }
  .kinto-service .service-description {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .kinto-service .service-description .row {
    grid-template-columns: 1fr;
    flex-wrap: wrap;
  }
  .kinto-service .service-description .subhead {
    max-width: unset;
  }
  .kinto-service .service-description .teaser p {
    font-size: 18px;
    font-size: 0.9rem;
  }
  .kinto-service .download-app-banner .container {
    flex-direction: column;
  }
  .kinto-service .download-app-banner .container .image-container {
    max-width: 100%;
  }
}

.kinto-service-selector .blocks .hero-full .title1 {
  margin-bottom: 3rem;
}
@media (max-width: 577px) {
  .kinto-service-selector .blocks .hero-full {
    height: unset;
  }
  .kinto-service-selector .blocks .hero-full .container {
    display: flex;
    flex-direction: column;
  }
  .kinto-service-selector .blocks .hero-full .container .service-selector {
    padding: 30px 0px 100px 0px;
  }
  .kinto-service-selector .blocks .hero-full .container .service-selector .card .new-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9747FF;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    top: 25px;
    right: 30px;
  }
  .kinto-service-selector .blocks .hero-full .container .service-selector .card .new-notification small {
    font-family: "ToyotaType", sans-serif;
    font-weight: 700;
    font-size: 11px;
    font-size: 0.55rem;
    line-height: 9px;
    text-align: center;
    color: #fff;
  }
}