.header {
    $self: &;
    position: fixed;
    min-width: 320px;
    top: 0;
    z-index: 3;
    width: 100%;
    background-color: $white;
    @include flexbox(row, nowrap, space-between, center);
    height: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    transition: top .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out;
    border-bottom: 1px solid $light-grey;

    &.-transparent {
        @include rwd-min(1170) {
            background-color: transparent;
            border-bottom-color: transparent;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include lg-min {
            width: auto;
        }
        
        &-link {
            display: inline-block;
            vertical-align: middle;
        }

        &-img {
            width: 118px;
        }
    }

    @include sm {
        &__logo {
            &-img {
                width: 100px;
            }
        }

        &__toggler__icon {
            height: 20px !important;
        }
    }

    &__toggler__icon {
        display: none;
        vertical-align: middle;
        width: 25px;
        height: 19px;
        margin-right: 10px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        @include rwd(1170) {
            display: inline-block;
        }

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: $ocean;
            border-radius: 5px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2) {
                top: 8.5px;
                height: 2.5px;
            }

            &:nth-child(3) {
                top: 16.5px;
                height: 2.5px;
            }
        }

        &.-active {
            span {
                &:nth-child(1) {
                    top: 8.5px;
                    transform: rotate(135deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    top: 8.5px;
                    transform: rotate(-135deg);
                }
            }
        }
    }

    &__primary-nav {
        position: relative;
        height: calc(100% + 2px);

        &__list {
            list-style: none;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: -1.75rem;
            margin-right: -1.75rem;
            height: 100%;

            display: flex;
            align-items: center;
        }

        &__item {
            height: 100%;
        }

        &__link {
            text-decoration: none;
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            height: 100%;
            @include font-size(14);
            font-weight: 600;
            color: $ocean;
            position: relative;
            @include flexbox(row, nowrap, center, center);
            transition: color .4s ease-in-out;

            &:after {
                content: '';
                display: block;
                height: 3px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $navy;
                opacity: 0;
                transition: opacity .4s ease-in-out;
            }

            &:hover,
            &:focus,
            &:active,
            &.active {
                color: $navy;
                text-decoration: none;

                &:after {
                    opacity: 1;
                }
            }
        }

        @include rwd(1170) {
            display: none;
            position: absolute;
            left: 0; right: 0; top: 100%;
            width: 100%;
            height: auto;
            background-color: rgba($white, .9);

            &__list {
                display: block;
                height: auto;
            }

            &__link {
                @include font-size(18);
                padding: 1rem;

                &:after {
                    content: none;
                }
            }
        }
    }
}