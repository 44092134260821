.kinto-service{
    .hero-full{
        text-align: center;
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 20px;
            .logo-container{
                max-width: 184px;
            }
        }
        &__rectangle {
            position: absolute;
            top: 120px;
            left: 0;
            padding: 80px 24px;
            width: fit-content;
            max-width: 40%;
            background-color: #00708D;
            color: white;
            text-align: start;
            font-size: clamp(38px, 5vw, 48px);
            line-height: 1;
            b {
                font-weight: 800;
            }

        }
    }
    .service-description{
        padding-bottom: 3rem;
        background: linear-gradient(88.16deg, #002732 1.91%, #00708D 100.88%);
        max-width: unset;
        margin: 0;
        padding-left: 10rem;
        padding-right: 10rem;
        &.light-theme {
          background: none;
          color: #00708D;
          .subhead {
            color: #002732;
          }
          .teaser {
              color: #002732;
          }
        }
        .row {
            display: flex;
            justify-content: center;
            column-gap: 60px;
        }
        .subhead {
            @include font-size(20);
            font-family: 'ToyotaType';
            font-weight: 600;
            line-height: 1.4;
            display: block;
            max-width: 435px;
            color: $white;
        }
    
        .teaser {
            @include font-size(18);
            line-height: 28px;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            color: $white;
            max-width: 635px;
            b{
                font-weight: 600
            }
        }
    }
    .cars-list {
        margin-bottom: 80px;

        .title-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 55px;
            h2{
                margin-bottom: 0px;
            }
        }


        .models-grid {
            padding-bottom: 0;
            border: none;

            &__item-data__buy {
                margin-top: 20px;
            }

        }

        .disclaimer {
            line-height: .6;
            margin-top: 20px;
            
            small {
                color: $steel-grey;
                font-size: .5rem;
            }
        }
    }
    .download-app-banner {
        background-color: #F5F5F5;
        .container {
            display: flex;
            justify-content: space-around;
            padding-top: 45px;
            .content-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .text-container {
                    margin-bottom: 40px;
                    h1 {
                        font-size: clamp(32px, 4vw, 50px);
                        margin-bottom: 24px;
                    }
                    p {
                        font-size: clamp(16px, 2vw, 20px);
                        color: #738184;
                        max-width: 410px;
                    }
                }
                .cta-container {
                    display: flex;
                    gap: clamp(14px, 2vw, 25px);
                    a {
                        padding: 0;
                        img {
                            max-width: 220px;
                        }
                    }
                }
            }
            .image-container {
                max-width: 50%;
                img {
                    width: 100%;
                }
            }
        }
    }
    @include sm{
        .hero-full {
            &__rectangle {
                padding: 40px 24px;
                top: auto;
                bottom: 0;
                max-width: 100%;
                width: 100%;
            }
        }
        .service-description{
            padding-left: 1rem;
            padding-right: 1rem;
            .row {
                grid-template-columns: 1fr;
                flex-wrap: wrap;
            }
            .subhead{
                max-width: unset
            }
            .teaser{
                p{
                    @include font-size(18);
                }
            }
        }
        .download-app-banner {
            .container {
                flex-direction: column;
                .content-container {
                    
                }
                .image-container {
                    max-width: 100%;
                }
            }
        }
    }
}