.custom-select {
    &__trigger {
        cursor: pointer;
        position: relative;
        @include font-size(30);
        font-weight: 600;
        line-height: 1.33;
        margin-bottom: 2rem;

        &:after {
            content: '';
            display: inline-block;
            width: 25px;
            height: 18px;
            margin-left: .75rem;
            background: no-repeat url('../img/icons/chevron-navy.svg') center center;
            background-size: contain;
        }
    }

    &__options {
        position: absolute;
        display: block;
        top: 100%; left: 0; right: 0;
        min-width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        color: $navy;
        border-bottom: none;
        box-shadow: 0 35px 55px 0 rgba(0, 0, 0, 0.1);
        background-color: $pale-blue;
        max-height: 15rem;
        display: flex;
        transition: all .4s ease-in-out;
        margin-top: .5rem;

        opacity: 0;
        z-index: 1;
        visibility: hidden;
        pointer-events: none;

        .container {
            overflow: auto;
        }

        &-option {
            position: relative;
            display: block;
            text-decoration: none;
            color: $steel-grey;
            font-weight: 500;
            font-size: 2rem;
            line-height: 1.5;
            letter-spacing: -0.56px;

            cursor: pointer;
            transition: all .4s ease-in-out;

            &:hover,
            &.selection {
                color: $navy;
            }
        }

    }

    &.opened .custom-select__options {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    @include sm {
        @include font-size(25);

        &.--dropdown {
            &:after {
                width: 20px;
                height: 15px;
            }
        }
    }
}