.body-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    margin-bottom: 50px;

    h1 {
        font-family: 'ToyotaType';
        @include font-size(50);
        font-weight: 400;
        line-height: 55px;
        color: $ocean;
        max-width: 900px;
        text-align: center;
    }

    p {
        @include font-size(18);
        line-height: 28px;
        color: $navy;
        max-width: 900px;
    }

    @include sm {
        h1 {
            @include font-size(30);
            line-height: 35px;
        }
    }
}

.info-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "pic data";
    grid-gap: 2rem;
    align-items: center;
    margin-bottom: 5rem;

    &:last-child {
        margin-bottom: 0;
    }

    &__pic {
        grid-area: pic;
        padding: 3rem 0 0 3rem;

        &-shadow {
            position: relative;
            display: inline-block;
            border-radius: 6px;
            background: $pale-blue; /* Old browsers */
            background: -moz-linear-gradient(-45deg,  rgba($pale-blue,.1) 0%, rgba($pale-blue,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg,  rgba($pale-blue,.1) 0%,rgba($pale-blue,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg,  rgba($pale-blue,.1) 0%,rgba($pale-blue,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &-img {
            position: relative;
            top: -3rem;
            left: -3rem;
            display: block;
            max-width: 100%;
            height: auto; 
            border-radius: 6px;
        }
    }

    &__data {
        grid-area: data;
        max-width: 380px;
        margin-left: 90px;
        &.plans-block{
            margin-left: 0px;
            max-width: 440px;
        }
        &-title {
            @include font-size(40);
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: -0.7px;
            margin-bottom: 1.5rem;
            &.ocean{
                color: $ocean;
                font-weight: 400
            }
        }

        &-subtitle {
            @include font-size(16);
            text-transform: uppercase;
            color: $ocean;
            font-weight: normal;
            line-height: 1.25;
            margin-bottom: .5rem;
        }
        // &.kinto-app-aside{
        //     width: 390px;
        // }

        &-cta {
            margin-top: 30px;
            a{
                font-family: 'ToyotaType';
                @include font-size(20);
                text-decoration: underline;
                color: $ocean;
                font-weight: 400;
            }
        }

        &-plans-container{
            margin-top: 20px;
            ol{
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                .plan{
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                    img{
                        max-width: 36px;
                        max-height: 36px
                    }
                    &__copy{
                        p{
                            @include font-size(20);
                            line-height: 27px;
                            b{
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
    
    $root: &;

    &.-reverse {
        grid-template-areas: "data pic";

        #{$root}__pic {
            padding: 3rem 3rem 0 0;

            &-img {
                right: -3rem;
                left: auto;
            }
        }

    }

    .download-section {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        .stores-section{
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top: 30px;
            .button--store{
                background-color: #fff;
                border: 1px solid #EBECED;
            }
        }
        .qr-section {
            margin-top: 30px;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    @include md {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:    "pic"
                                "data";

        &.-reverse {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas:    "data"
                                    "pic";
        }
    }

    @include sm {
        margin-bottom: 3rem;

        &__pic {
            padding: 2rem 0 0 2rem;

            &-img {
                top: -2rem;
                left: -2rem;
            }
        }

        &__data {
            margin-left: 0px;
            &-title {
                @include font-size(25);
            }
            &-copy{
                p{
                    @include font-size(16);
                    line-height: 28px;
                }
            }
        }

        &.-reverse {
            #{$root}__pic {
                padding: 2rem 2rem 0 0;

                &-img {
                    right: -2rem;
                }
            }
        }
    }
}