.cotizador {
    h4 {
        font-size: 25px;
        line-height: 32px;
        color: $navy;
        margin: 15px 0;
        font-weight: 600;
    }
    
    h5 {
        font-size: 16px;
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 1rem;
        line-height: 1.27;
    }
    
    .title1 {
        margin-bottom: 20px;
    }

    .button-group-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
            background: $white;
            padding: 12px 15px;
            border: 1.5px solid $ocean;
            border-radius: 6px;
            font-size: 16px;
            color: $navy;
            font-weight: 600;
            margin-right: 15px;
            cursor: pointer;
            transition: background 100ms ease-in-out;
            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: $navy;
                color: $white;
            }

            &:disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }

    .downpayment_range {
        width: 100%;
        max-width: 470px;
        display: flex;
        flex-direction: column;
        
        input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            max-width: 450px;
            height: 8px;
            border-radius: 4px;
            background: $light-grey;
            background-image: linear-gradient($ocean, $ocean);
            background-size: 0% 100%;
            background-repeat: no-repeat;
    
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                background: $white;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                // margin-top: -12px;
                box-shadow: 0px 4px 8px -2px #1018281A;
                transition: background .3s ease-in-out;
                border: 1px solid #ddd;
            }
    
            &::-webkit-slider-runnable-track  {
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                background: transparent;
            }
        }

        .tickmarks {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            span {
                color: $navy;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                left: -3px;
            }
        }
    }
    
}

//Definimos ids a imprimir
@media print
{
    .print { display: block; }
    .no-print { display: none!important; }
}

.time-and-model {
    margin-bottom: 50px;
    .title3 {
        font-size: 25px;
        font-size: 1.25rem;
        margin-bottom: 15px;
    }
    .left-section {
        margin-bottom: 40px;
        .form-group-cotizador {
            margin-bottom: 25px;
            span {
                @include font-size(10);
                position: absolute;
                bottom: -15px;
            }
        }
    }
}

.subtitle {
    color: $ocean;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.copy {
    p {
        margin-bottom: 50px;
        a {
            text-decoration: underline;
            color: $navy;
        }
    }
}

.chev-down {
    background: no-repeat url('../img/icons/chevron-navy.svg') center center;
}

/*Radiobutton*/
.custom-radio {
    @include flexbox(row, wrap, space-around);
    @media (max-width: $screen-md) {
        flex-direction: column;
        padding-left: 25px;
    }
}
.custom-radio div{
    font-weight: 300;
    @media (max-width: $screen-md) {
        margin-bottom: 5px;
    }
}

.radio-custom {
    opacity: 0;
    position: absolute;
}

.radio-custom-label {
    position: relative;
    display: inline-block;
    cursor:pointer;
    font-weight: 500;
    @include flexbox(row, wrap, start, center);
    &:before {
        content: '';
        border: 2px solid $aqua-blue;
        display: inline-block;
        vertical-align: middle;
        padding: 2px;
        margin-right: 10px;
        border-radius:50%;
    }
    &.small {
        height: 28px;
        &:before {
            width: 22px;
            height: 22px;
        }
    }
    &.large {
        height: 34px;
        margin-bottom: 0.5rem;
        @include font-size(16);
        font-weight: 600;
        line-height: 1.27;
        &:before {
            margin-right: 15px;
            width: 30px;
            height: 30px;
            @media (max-width: $screen-md) {
                margin-right: 5px;
            }
        }
    }
}

.radio-custom:checked+.radio-custom-label:before {
    background: $gradient-aqua;
}

.radio-custom:checked+.radio-custom-label {
    &:after {
        content: "";
        display: block;
        position: absolute;
        background: $navy;
        border-radius: 50%;
    }
    &.small {
        &:after {
            width: 12px;
            height: 12px;
            top: calc(50% - 6px);
            left: 5px;
        }
    }
    &.large {
        &:after {
            width: 16px;
            height: 16px;
            top: calc(50% - 8px);
            left: 7px;
        }
    }
}

.form-group-cotizador {
    @include flexbox;
    @media (max-width: $screen-md) {
        margin-bottom: 25px;
    }
}

.form-control {
    font-family: "ToyotaType", sans-serif;
    display: block;
    padding: 0.1rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.5;
    color: $navy;
    background-color: $white;
    background-clip: padding-box;
    border: 1.5px solid $aqua-blue;
    border-radius: 0.3rem;
    outline: none;
    &.select-time {
        width: 40%;
        margin-right: 20px;
        @media (max-width: $screen-md) {
            width: 65%;
        }
    }
    &.time-input {
        width: 80px;
        @media (max-width: $screen-md) {
            width: 60px;
        }
    }
    &.material-select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        font-size: 1rem;
        width: 100%;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 0.3rem;
        padding-left: 0;
        cursor: pointer;
        background: no-repeat url('../img/icons/chevron-navy.svg') center;
        background-size: 14px;
        background-position-x: 99%;
    }
    &.chev {
        -moz-appearance: none;
        -webkit-appearance: none;
        background: no-repeat url('../img/icons/chevron-navy.svg') center;
        background-size: 14px;
        background-position-x: 95%;
        background-position-y: 60%;
    }
}

.car-list {
    background-color: $white;
    position: relative;
    ul {
        overflow-y: auto;
        display: none;
        width: 100%;
        max-height: 260px;
        box-shadow: 0px 5px 15px $light-grey;
        border-radius: 0 0 5px 5px;
        position: absolute;
        z-index: 200;
        top: 39px;
        background-color: $white;
        &.active {
            display: block
        }
        li {
            display: grid;
            grid-template-columns: 1fr 3fr;
            grid-gap: 20px;
            align-items: center;
            border-bottom: 1px solid $pale-blue;
            padding: 5px 10px;
            cursor: pointer; 
            &:hover {
                background-color: $pale-blue;
            }
            &:last-child {
                border-bottom: none;
            }
            .thumb-select-car {
                @include flexbox(row, wrap, center, center);
                min-height: 55px;
                img {
                    width: 100%;
                }
            }
            small {
                display: block;
                @include font-size(10);
                font-weight: normal;
            }
            h4 {
                @include font-size(20);
                margin: 0;
            }
            &.is-modal {
                grid-template-columns: 1fr;
                padding: 10px 20px;
                .thumb-select-car {
                    display: none;
                }
            }
        }
    }
}

select {
    cursor: pointer;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 0;
    @media (max-width: $screen-md) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
    }
}

.print-compare {
    .print-icon {
        cursor: pointer;
    }
    @media (min-width: $screen-md) {
        @include flexbox;
    }
}

//Info message
.box {
    border-radius: 0.3rem;
    padding: 10px 40px;
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 15px;
    &.info {
        background-color: $light-aqua;
        a {
            &:hover {
                cursor: pointer;
                color: $ocean;
            }
        }
    }
}

.price-card {
    .list-price {
        p {
            font-weight: normal;
            display: inline-block;
            min-width: 75px;
        }
        small {
            font-size: 10px;
            font-size: 0.5rem;
            color: $navy;
            font-weight: normal;
        }
    }
    .franchise {
        display: block;
        font-size: 10px;
        font-size: 0.5rem;
        color: $steel-grey;
        margin-top: 0.5rem;
    }
}

//Component TimeAndModel
.time-container {
    border-right: 1px solid $light-grey;
    padding-right: 40px;
    height: 100%;
    @media (max-width: $screen-md) {
        border-right: none;
        padding-right: 0;
        padding-bottom: 50px;
        border-bottom: 1px solid $light-grey;
    }
    .instructions{
        ol{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            li{
                display: flex;
                .check{
                    background:  url('../img/icons/check.svg') no-repeat center;
                    height: 30px;
                    padding-top: 7px;
                    padding-left: 25px;
                }
                p{
                    font-size: 0.9rem;
                    margin-left: 10px;
                    strong{
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.cercania {
    p {
        font-size: 0.8rem;
    }
    form {
        margin: 5px 0 10px 0;
        label {
            font-size: 0.8rem;
        }
    }
    @media (max-width: $screen-md) {
        padding-left: 25px;
    }
}

.otra-sucursal {
    p {
        @include font-size(16);
        a {
            text-decoration: underline;
            color: $navy;
        }
    }
}

.necesitas-que {
    @include flexbox(row, wrap, space-between, center);
    font-size: 0.8rem;
    margin-bottom: 10px;
    padding: 0 25px;
    @media (max-width: $screen-md) {
        padding-right: 5px;
    }
    span {
        @media (max-width: $screen-md) {
            margin-bottom: 10px;
        }
    }
    select {
        width: 70%;
        @media (max-width: $screen-md) {
            width: 100%;
        }
    }
}

.right-section {
    @media (min-width: $screen-md) {
        padding-left: 40px;
    }
    @media (max-width: 1100px) {
        padding-left: 20px;
    }
    @media (max-width: 769px) {
        padding-left: 0;
    }
}

//Component SelectedCarModel

.model-image {
    height: 180px;
    @include flexbox(row, wrap, center, center);
    img {
        height: 100%;
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }

    @media (max-width: 1100px) {
        height: auto;
        width: 100%;
    }
}

.add-extra {
    @include flexbox(row, wrap, flex-end, flex-end);
    @media (max-width: $screen-md) {
        .button {
            width: 100%;
        }
    }
}

.model-actions {
    text-align: center;
    margin-bottom: 41px;
    .spec-link {
        margin-top: 12px;

        a {
            font-size: 0.8rem;
            margin: 10px 0;
            color: $navy;
            cursor: pointer;
            font-size: 16px;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }
    span {
        font-size: 0.7rem;
        font-weight: 600;
        cursor: pointer;
        color: $navy;
    }

    @media (max-width: 550px) {
        margin-bottom: 30px;
    }
}

.model-details {
    display: flex;
    justify-content: center;
    
    .detail {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr 1fr;
        padding-left: 10px;
        padding-right: 10px;
        
        .desde {
            grid-column: 1 / -1;
            grid-row: 1;
            font-size: 12px;
        }

        .price {
            grid-column: 1;
            grid-row: 2;
            font-size: 20px;
            line-height: 20px;
            margin-right: 5px;
        }

        .months {
            grid-column: 2;
            grid-row: 2;
            font-size: 12px;
            line-height: 0.8;
        }
    }

    .line {
        background-color: $light-grey;
        width: 1px;
    }

    @media (max-width: 1150px) {
        .detail {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media (max-width: 1020px) { 
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    @media (max-width: 550px) {
        flex-direction: column;

        .line, .desde {
            display: none;
        }

        .detail {
            display: flex;
            margin: auto;
            margin-bottom: 4px;
            align-items: center;

            br {
                display: none;
            }
        }

    }
}
.selected-car-model {
    margin-top: 1.5rem;
    .compare-models-link {
        i {
            img {
                width: 10px;
                height: 10px;
                transform: rotate(-90deg);
            }
        }
    }
    &.disabled {
        a, span {
            color: $light-grey;
            pointer-events:none;
        }
        .compare-models-link {
            i {
                img {
                    filter: invert(95%) sepia(10%) saturate(59%) hue-rotate(161deg) brightness(94%) contrast(82%);
                }
            }
        }
    }
}

.extras {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 0;
    &.disabled {
        .button {
            color: #d4d7d9;
            border-color: #d4d7d9;
            pointer-events:none;
        }
    }
}

.modal {
    &.show {
        display: flex;
    }
}

//Component CompareModal
.compare-modal {
    .modal {
        .modal-box {
            margin: 0 auto;
            .modal-header {
                min-height: 150px;
                padding: 1.5rem 3rem;
                h3 {
                    @include font-size(30px);
                }
                .modal-close {
                    top: calc(50% - 45px);
                    font-weight: 600;
                    @media (max-width: $screen-md) {
                        right: 1rem;
                    }
                }
                @media (max-width: $screen-md) {
                    padding: 30px 16px;
                }
            }
            .modal-content {
                padding: 1.5rem 3rem;
                ul {
                    margin: 0;
                }
                .modal-body {
                    margin-top: -85px;
                    padding: 0 30px 50px 30px;
                    @media (max-width: $screen-md) {
                        padding: 0 0 30px 0;
                        margin-top: -75px;
                    }
                }
                .modal-footer {
                    @include flexbox(row, wrap, space-between);
                    border-top: 1px solid $light-grey;
                    background-color: transparent;
                    padding: 25px 0;
                    @media (max-width: $screen-md) {
                        @include flexbox(row, wrap, center);
                        .button {
                            margin: 15px 0 20px;
                        }
                    }
                }
                @media (max-width: $screen-md) {
                    padding: 30px 15px 20px 15px;
                }
            }
        }
    }
    .models-to-compare-grid {
        .regular {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 80px;
        }
        @media (max-width: $screen-md) {
            display: block;
            .slick-track {
                .model-to-compare {
                    padding: 0 40px;
                }
            }
        }
    }
    //Slick styles
    .slick-slider .slick-arrow {
        display: block!important;
        background: none;
        height: 90px;
        &:after {
            background-image: url('../img/icons/chevron-navy.svg');
        }
        &.slick-next {
            right: -15px;
        }
        &.slick-prev {
            left: -15px;
        }
    }
}

//Component AdicionalesModal
.titleBlue {
    @include font-size(25);
    color: $ocean;
}

.hr2 {
    height: 2px;
    margin: 30px 0;
    background-color: $pale-grey;
    @media (max-width: $screen-md) {
        margin: 40px 0;
    }
}

.adicionales-modal {
    .modal {
        .modal-box {
            .modal-header {
                padding: 30px 65px;
                h3 {
                    @include font-size(30);
                    @media (max-width: $screen-md) {
                        @include font-size(25);
                    }
                }
                .modal-close {
                    font-weight: 600;
                }
                @media (max-width: $screen-md) {
                    padding: 30px 16px;
                    border-radius: 0;
                }
            }
            .modal-content {
                padding: 30px 65px 50px 65px;
                @media (max-width: $screen-md) {
                    padding: 30px 15px 50px 15px;
                }
            }
        }
    }
    .adicionales-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
            grid-gap: 30px;
        }
        .adicionales-card {
            @media (min-width: $screen-md) {
                &:nth-child(even) {
                    padding-left: 80px;
                    padding-right: 30px;
                }
                &:nth-child(odd) {
                    padding-right: 80px;
                    padding-left: 30px;
                    border-right: 1px solid $light-grey;
                }
            }
        }
        p {
            @include font-size(14);
            line-height: 1.71;
        }
        .adicionales-footer {
            @include flexbox(row, wrap, space-between, center);
            margin-top: 20px;
            .title4 {
                margin: 0;
                span {
                    font-weight: 500; 
                }
            }
            .button {
                min-width: 150px;
                border: 2px solid;
            }
        }
    }
}

//Component ContactModal
.modal {
    .modal-box {
        .modal-header {
            padding: 30px 65px;
            .modal-close {
                font-weight: 600;
            }
            @media (max-width: $screen-md) {
                padding: 30px 15px;
                border-radius: 0;
            }
        }
        .modal-content {
            padding: 30px 65px 50px 65px;
            @media (max-width: $screen-md) {
                padding: 30px 15px 50px 15px;
            }
        }
        .contact-container {
            .title4 {
                margin: 0 0 20px;
            }
            p {
                @include font-size(14);
                margin-bottom: 20px;
                line-height: 1.71;
            }
            .info-section {
                position: relative;
                padding-right: 45px;
            }
            .contact-section {
                .form__row {
                    @include flexbox(row, nowrap, flex-end, center);
                }

                @include md-min {
                    padding-left: 45px;
                }
            }
            .social-media {
                position: absolute;
                bottom: 0;
                @include sm{
                    position: initial;
                }
                .subtitle {
                    @include font-size(18);
                    color: $navy;
                    margin-bottom: 30px;
                }
                .social-media-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    @include flexbox(row, nowrap, flex-start, center);
                    li {
                        display: inline-block;
                        margin-right: 1.5rem;
                        a {
                            svg {
                                .inner {
                                    fill: $white;
                                    transition: fill 0.2s ease;
                                }
                                .outer {
                                    fill: $ocean;
                                    transition: fill 0.2s ease;
                                }
                                &:hover {
                                    .inner {
                                        fill: $ocean;
                                    }
                                    .outer {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                }
                @include sm{
                    display: none;
                }
                &.social-media-footer{
                    @include sm{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        .social-media-list{
                            justify-content: center;
                            column-gap: 20px;
                            li{
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

//Component ContactConfirmationModal
.contact-confirmation-modal {
    .modal-box {
        .modal-header {
            padding: 30px 65px;
            background-color: $pale-grey;
            .modal-close {
                font-weight: 600;
            }
            @media (max-width: $screen-md) {
                padding: 30px 15px;
                border-radius: 0;
            }
        }
        .modal-content {
            background-color: $pale-grey;
            @media (min-width: $screen-md) {
                padding: 80px 65px 80px 65px;
                border-radius: 6px 6px;
            }
            .modal-body {
                .contact-confirmation-container {
                    @include flexbox(column, wrap, center, center);
                    .title {
                        margin: 20px 0 15px 0;
                    }
                }
            }
        }
    }
}

//Component DoorToDoorModal
.door-to-door-modal {
    .modal-box {
        .modal-header {
            padding: 30px 65px;
            .modal-close {
                font-weight: 600;
            }
            @media (max-width: $screen-md) {
                padding: 30px 15px;
                border-radius: 0;
            }
        }
        .modal-content {
            padding: 30px 65px 50px 65px;
            @media (max-width: $screen-md) {
                padding: 30px 15px 50px 15px;
            }
            .modal-body {
                ul {
                    li {
                        @include font-size(18);
                        line-height: 1.56;
                        margin-bottom: 20px;
                        list-style: none;
                        text-align: left;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.large {
            max-width: 930px;
        }
    }
}

//Component ModelToCompare
.model-to-compare {
    .material-select {
        border-bottom: 1px solid $navy;
        &.bold {
            font-weight: 600;
        }
    }
    .model-image {
        height: 120px;
    }
    .model-price-list {
        ul {
            li {
                @include flexbox(row, wrap, space-between, center);
                padding: 10px 0;
                border-bottom: 1px solid $light-grey;
                list-style: none!important;
                &:last-child {
                    border-bottom: 1px solid $navy;
                }
            }
        }
    }
    .total-price-box {
        margin-top: 1rem;
        p {
            font-weight: 600;
            span {
                @include font-size(30);
            }
        }
    }
    &.empty {
        .model-price-list {
            color: $light-grey;
            font-weight: 300;
        }
        .total-price-box {
            color: $light-grey;
        }
    }
    .empty-price {
        letter-spacing: -5px;
        margin-right: 5px;
    }
}

//Component Footer
.footer-cotizador {
    height: 100%;
    background-color: $white;
    @media (max-width: $screen-md) {
        height: 100%;
    }
    .footer-body {
        @include flexbox(row, wrap, space-between);
        padding: 30px 0 15px 0;
        .title3 {
            margin-bottom: 0;
            @media (max-width: $screen-md) {
                @include font-size(18);
                margin-bottom: 15px;
            }
        }
        .total-price {
            text-align: right;
            @media (max-width: $screen-md) {
                text-align: left;
                .title3 {
                    margin-bottom: 0;
                }
            }
        }
        div {
            a {
                text-decoration: none;
                color: $navy;
                span {
                    @include font-size(14);
                    font-weight: 600;
                    cursor: pointer;
                    img {
                        width: 10px;
                        height: 10px;
                    }
                }
                &.active {
                    span {
                        img {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            p {
                margin-top: 5px;
                @include font-size(16);
            }
        }
    }
    .footer-bottom {
        .container {
            .options {
                @include flexbox(row, wrap, flex-end);
                border-top: 2px solid $pale-light-grey;
                padding: 15px 0;
                gap: 16px;
                .button{
                    padding-bottom: 8px;
                }
                @media (max-width: $screen-md) {
                    text-align: left;
                    align-items: center;
                    flex-direction: column-reverse;
                    border-top: none;
                    padding: 0;
                    width: 100%;
                    padding-bottom: 20px;
                    .button {
                        padding: 5px 25px 8px 25px;
                        width: 247px;
                    }
                } 
            }
        }
        &.active {
            background-color: $white;
            .container {
                .options {
                    border-top: 0;
                }
            }
        }
    }
    .selected-model, .selected-days {
        &.disabled {
            color: $cool-grey;
        }
    }
    .resumen {
        background-color: $white;
        display: none;
        &.active {
            display: block;
        }
        .title-resumen {
            @include font-size(18);
        }
        ul {
            padding-top: 30px;
            li {
                @include flexbox(row, wrap, space-between, flex-end);
                padding: 10px 0;
                border-bottom: 2px solid $pale-grey;
                color: $dark-blue;
                &:first-child {
                    color: $navy;
                }
                .price {
                    text-align: right;
                }
                .description {
                    width: 65%;
                }
                p {
                    @include font-size(14);
                    small {
                        color: $steel-grey;
                        @include font-size(12);
                    }
                }
            }
        }
        .small {
            padding: 30px 0;
            line-height: 1.2;
            border-bottom: 2px solid $pale-light-grey;
            @include font-size(10);
        }
    }
    &.disabled {
        .footer-body {
            div {
                a {
                    span {
                        color: $dark-cool-grey;
                        cursor: default;
                        img {
                            filter: invert(76%) sepia(20%) saturate(175%) hue-rotate(146deg) brightness(93%) contrast(85%);
                        }
                    }
                }
            }
        }
        .footer-bottom {
            .print-compare {
                .print-icon {
                    cursor: default;
                }
                img {
                    filter: invert(76%) sepia(20%) saturate(175%) hue-rotate(146deg) brightness(93%) contrast(85%);
                }
            }
        }
    }
}

// overrides para one personal
.cotizador.one_personal {
    .time-container {
        .options {
            margin-top: 15px;

            &+h3 {
                margin-top: 50px;

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }
    .extras {
        display: block;

        .list-price {
            text-align: center;
            br {
                display: none;
            }
        }

        @include md-min {
            .price-card {
                display: flex;
                justify-content: space-between;

                .list-price {
                    font-size: 12px;
                    font-weight: 300;
                    text-align: left;
                    display: flex;
                    align-items: flex-end;

                    .price {
                        font-size: 20px;
                    }
                    
                    br {
                        display: initial;
                    }

                    small {
                        display: block;
                        max-width: 50px;
                        margin-left: 5px;
                    }

                    &:nth-child(2) {
                        border-left: 1px solid $light-grey;
                        border-right: 1px solid $light-grey;
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    // aca
    .resumen {
        .title-resumen.description {
            @include font-size(14);
        }
    }
}

#cotizador_root {
    position: relative;
    #cotizador_overlay {
        position: absolute;
        background: #ffffffcc;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: none;
    }
}