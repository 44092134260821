.modal {
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    @include flexbox(row, nowrap, center, center);
    display: none;
    

    .modal-box {
        width: 100%;
        background-color: $white;
        border-radius: 6px;
        max-width: 800px;

        &.small {
            max-width: 500px;
        }
        &.medium {
            max-width: 800px;
        }
        &.large {
            max-width: 1110px;
        }

        .modal-header {
            padding: 1rem 4rem 1rem 2rem;
            background-color: $pale-blue;
            border-radius: 6px;

            .title3 {
                margin-bottom: 0;
                font-weight: 500;
            }

            .modal-close {
                border: 0;
                background: none;
                outline: none;
                margin: 0;
                position: absolute;
                top: calc(50% - 14px);
                right: 2rem;
                z-index: 10;
                @include font-size(20);
                font-weight: 300;
                line-height: 0.8;
                padding: 5px;
                color: $navy;
                cursor: pointer;
            }
        }
        .modal-content {
            padding: 1.5rem 2rem;

            ul {
                margin-left: 9px;
                &,li {
                    list-style: disc;
                }
            }
        }

        .modal-footer {
            background-color: $pale-blue;
            padding: 15px 30px;
            font-weight: 300;
            @include font-size(15);
            text-align: center;

            &:empty {
                padding-top: 0;
                display: none;
            }
        }
    }

    @include md {
        @include flexbox(column, wrap);
        display: none;

        &:not(.prevent-fs) {
            padding: 0;
            overflow: auto;
            height: 100%;

            .modal-box {
                border: 0;
                border-radius: 0;
                position: static;
                display: flex;
                flex-direction: column;
                flex: 1;

                .push-footer {
                    flex: 1 0 auto;

                    .modal-header {
                        position: fixed;
                        top: 0; left: 0; right: 0;
                        z-index: 10;
                        padding: .7rem 2.5rem .7rem 1rem;
                        border-radius: 0;

                        .modal-close {
                            top: calc(50% - 17px);
                            padding: 10px;
                            right: 6px;
                        }
                    }
                    .modal-content {
                        padding: 4rem 0.7rem 1rem;
                    }

                    &.small,
                    &.medium,
                    &.big {
                        max-width: none;
                    }
                }

                .modal-footer {
                    flex-shrink: 0;
                }
            }
        }
        &.prevent-fs {
            padding: .5rem;
            .modal-box {
                border: 0;

                .modal-header {
                    padding: .7rem 3rem .7rem 1rem;

                    .modal-close {
                        right: 1rem;
                    }
                }
                .modal-content {
                    padding: .7rem 1rem;
                }
            }
        }
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 0;
        grid-template-areas:    "info form"
                                "title form";

        &-title {
            grid-area: title;
            padding-right: 4rem;
            align-self: end;
        }
        &-info {
            grid-area: info;
            padding-right: 4rem;
        }
        &-form {
            grid-area: form;
            border-left: 1px solid $navy;
            padding-left: 4rem;
        }
    }

    @include md {
        &__row {
            grid-column-gap: 0;
            grid-row-gap: 2rem;
            grid-template-areas:    "info info"
                                    "title title"
                                    "form form";

            &-form {
                border-left: 0;
                padding-left: 0;
                margin-bottom: 1rem;
            }

            &-title,
            &-info {
                padding-right: 0;
            }
        }
    }

    @include sm {
        &__row {
            &-title {
                .title3 {
                    @include font-size(22);
                    margin-bottom: 1rem;
                }
            }

            &-info {
                &-schedule {
                    display: none;
                }
            }
        }
    }
}

.form__input:active + {
    bottom: 1.5rem;
}

#modal_one_personal {
    .info-section {
        display: flex;
        flex-direction: column;
        max-height: 87%;

        .form__disclaimer {
            margin-top: auto;
        }
    }
}

.modal#promo-modal, .modal#onepersonalsim-modal {
  // display: block !important;
  
  .modal-wrapper {
    max-width: 100%;
  }
  .modal-box {
    border-radius: 16px;
  }
  .modal-content {
    padding: 0;
    .modal-body{
      display: flex;
      position: relative;
      flex-direction: row;
      .modal-close {
        position: absolute;
        top: 26px;
        right: 25px;
        cursor: pointer;
        z-index: 10;
        text-shadow: 0px 0px 1px #FFFFFF;
      }
      .left-section{
        display: flex;
        align-items: center;
        max-width: 45%;
        img {
          height: 100%;
          min-width: 100%;
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;
        }
      }
      .right-section{
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        align-items: center;
        .title3 {
          max-width: 410px;
        }
        .text-content {
          margin-bottom: 56px;
          font-size: 16px;
        }
        .button-wrapper {
          display: flex;

          .modal-close {
            position: relative;
            right: 0;
            top: 0;
          }
        }

        @media (max-width: $screen-md) {
          padding: 40px 24px;
          width: 100%;
          .title3 {
            margin-bottom: 20px;
          }
          .text-content {
            font-size: 14px;
            margin-bottom: 40px;
          }
          .button-wrapper {
            justify-content: center;
          }
        }
      }
      @media (max-width: $screen-md) {
        flex-direction: column;
        .modal-close {
          top: 15px;
          right: 20px;
        }
        .left-section, .right-section{
          width: 100%;
          overflow: hidden;
          max-width: 100%;
        }
        .left-section{
          max-height: 200px;
          object-fit: cover;
          border-top-right-radius: 16px;
          border-top-left-radius: 16px;
          img {
            height: 100%;
          }
        }
      }
    }
  }
  @media (max-width: $screen-md) {
    padding: 100px 16px 0;
  }
}

.modal.personal-data-modal {
    // display: block !important;
    
    .modal-wrapper {
        max-width: 100%;
    }
    .modal-box {
        border-radius: 16px;
        padding: 30px;
        max-width: 570px;

        .form__input-wrapper, .form__input-aside, .form__text, .disclaimer {
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
        .form__row {
            margin-top: 1rem;

            div, aside {
                margin-bottom: 0;
            }
        }
    }
    .modal-content {
        padding: 0;

        .modal-body{
            display: flex;
            position: relative;
            flex-direction: row;

            .form-title {
                font-size: 30px;
                line-height: 37px;
                color: $navy;
                margin-bottom: 30px;
                text-align: center;
                font-weight: 600;
            }

            .form__label {
                color: $navy;
            }

            .form__input, .form__textarea {
                border-bottom: 1px solid $navy;
                font-family: "ToyotaType", sans-serif;
            }

            .form__text {
                font-size: 16px;
                line-height: 21px;
                margin: 32px 0;
            }

            .form__row {
                justify-content: center;
            }

            .disclaimer {
                font-size: 12px;
                line-height: 20px;
                display: flex;
                .checkbox-text{
                    font-size: 12px;
                }

                .custom-checkbox {
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    // margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 10px;
                    user-select: none;
                    .checkmark {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        height: 14px;
                        width: 14px;
                        border-radius: 4px;
                        border: solid 1px $navy;
                        background-color: $white;
                        &:after {
                            content: "";
                            position: absolute;
                            display: none;
                            left: 4px;
                            top: 1px;
                            width: 4px;
                            height: 8px;
                            border: solid $navy;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                    .error {
                        position: absolute;
                        top: 1rem;
                        white-space: nowrap;
                        font-size: .6rem;
                        line-height: 1.2rem;
                        color: $navy;
                        font-weight: 500;
                    }
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        pointer-events: none;
                        &:checked ~ .checkmark {
                            background-color: $pale-blue
                        }
                        &:checked ~ .checkmark:after {
                            display: block;
                        }
                    }
                    &:hover input ~ .checkmark {
                        background-color: $pale-blue
                    }
                }
            }

            @media (max-width: $screen-md) {
                flex-direction: column;
            }
        }
    }
    @media (max-width: $screen-md) {
        padding: 0 !important;
        background-color: $white;

        .modal-box {
            padding: 50px 15px;
        }

        .modal-content {
            .modal-body {
                align-items: center;
                width: 100vw;
                
                .form-title {
                    max-width: 266px;
                    margin-bottom: 40px;
                    align-self: center;
                    font-size: 25px;
                    line-height: 32px;
                }
                .form__input-wrapper, .form__input-aside {
                    margin-top: 1rem;
                }
                .form__text {
                    margin: 16px 0;;
                }
            }
        }
    }
}