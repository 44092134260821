.hero-full {
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background: no-repeat center center #f5f7e9;
    background-size: cover;
    padding-top: 120px;

    .title1 {
        margin-bottom: 1rem;
    }

    &__copy {
        max-width: 730px;
    }

    @include sm {
        &__copy {
            p {
                @include font-size(16);
            }
        }
    }
}

.cards-container{
    position: relative;
    top: -75px;
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    .card{
        background-color: $white;
        max-width: 360px;
        padding: 25px 25px;
        border-radius: 16px;
        text-align: center;
        border: 0.5px solid #A5B0B3;
        @include flexbox(column, wrap, center, center);
        row-gap: 15px;
        .new-notification{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #9747FF;
            padding: 10px;
            border-radius: 8px;
            position: absolute;
            top: 25px;
            right: 30px;
            small{
                font-family: 'ToyotaType', sans-serif;
                font-weight: 700;
                @include font-size(11);
                line-height: 9px;
                text-align: center;
                color: $white;
            }
        }
        &__title{
            color: $ocean;
            font-weight: 400;
            @include font-size(16);
            font-family: "ToyotaType", sans-serif;
        }
        &__content{
            @include flexbox(column, wrap, center, center);
            row-gap: 20px;
        }
        &__copy{
            font-family: "ToyotaType", sans-serif;
            font-weight: 400;
            @include font-size(18);
            line-height: 28px;
            color: $navy;
        }
    }

    @include sm {
        padding: 0px 10px;
        .card{
            width: 100%;
            &__copy{
                display: none;
            }
        }
    }

    &.service-selector{
        top: 0px;
        position: initial;
        padding-top: 50px;
        .card{
            max-width: 360px;
            &__content{
                row-gap: 10px;
                p{
                    @include font-size(18);
                    font-weight: 400;
                    line-height: 28px;
                }
                .links-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 2px;
                    row-gap: 10px;
                    a{
                        @include font-size(13);
                        line-height: 23px;
                    }
                }
            }
        }
        @include sm{
            top: 0px;
            .card{
                padding: 25px 10px;
                &__content{
                    .links-container{
                        column-gap: 5px;
                        a{
                            min-width: 7rem;
                        }
                    }
                }
            }
        }
    }

}