.sections-grid {
    display: grid;
    grid-template-columns: .65fr 1.35fr;
    grid-gap: 50px;

    &__app {
        background-color: $pale-blue;
        padding: 35px;
        border-radius: 6px;

        &__illus {
            display: block;
            max-width: 100%;
            height: auto;
            margin-bottom: 1.5rem;
            max-width: 300px;
        }

        &__copy {
            margin-bottom: 1.5rem;
        }

        .button--store:not(:last-child) {
            margin-bottom: 1rem;

        }

    }

    &__sections {
        @include flexbox(column, nowrap, space-between, start);
        padding: 2rem 0;

        .separator {
            margin: 2rem 0;
        }

        &__row {
            @include flexbox(row, nowrap, space-between, center);

            &-left,
            &-right {
                width: 46%;

                &__illus {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
            }

            &.-reverse {
                flex-direction: row-reverse;
            }
        }
    }

    @include md {
        display: block;
        
        &__sections {
            display: block;
        }
    }

    @include sm {
        &__sections {
            &__row {
                display: block;

                &-left {
                    width: 100%;
                    margin-bottom: 1rem;
                }

                &-right {
                    width: 100%;
                }
            }
        }
    }
}