.-align-left {
    text-align: left !important;
    justify-content: flex-start !important;
}

.-align-right {
    text-align: right !important;
    justify-content: flex-end !important;
}

.-align-center {
    text-align: center !important;
    justify-content: center !important;
}

.-center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.-hide {
    display: none;
}

.-header-offset {
    padding-top: 7rem;
}

.-block-top {
    padding-top: 5rem;
}
.-block-bottom {
    padding-bottom: 5rem;
}


.-bg-navy {
    background-color: $navy;
    color: $white;
}

.-bg-ocean {
    background-color: $ocean;
    color: $white;
}

.-bg-pale-blue {
    background-color: $pale-blue;
}

@include md {
    .-block-top {
        padding-top: 4rem;
    }
    .-block-bottom {
        padding-bottom: 4rem;
    }
    .-md-align-center {
        text-align: center !important;
        justify-content: center !important;
    }

    .-header-offset {
        padding-top: 6rem;
    }
}

@include sm {
    .-block-top {
        padding-top: 3rem;
    }
    .-block-bottom {
        padding-bottom: 3rem;
    }

    .-header-offset {
        padding-top: 5rem;
    }
}

.-fs-14 {
    @include font-size(14);
    line-height: 1.5;

    h4 {
        @include font-size(20);
        font-weight: 700;
    }
}