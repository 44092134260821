.functions-container {
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
        font-family: 'ToyotaType';
        @include font-size(50);
        font-weight: 400;
        line-height: 55px;
        color: $ocean;
        text-align: center;
    }

    &.dark-theme {
      background: linear-gradient(88.16deg, #002732 1.91%, #00708D 100.88%);
      color: #FFFFFF;
      h1 {
        color: #FFFFFF;
      }
      @include lg-min {
        flex-direction: row;
        align-items: initial;

        .function-item {

            &::before, &::after {
                border-bottom: 2px dashed #D4D7D9 !important;
            }

        }
      }
    }

    .function-items-container {
        display: flex;
        flex-direction: column;
        column-gap: 30px;
        margin-top: 80px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 40px;

        @include lg-min {
            flex-direction: row;
            align-items: initial;

            .function-item {
                position: relative;

                img {
                  z-index: 2;
                }

                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 50%;
                    height: 50px;
                    border-bottom: 2px dashed $light-grey;
                }

                &::before {
                    // background: #ff00ffaa;
                    left: -14px;
                }

                &::after {
                    // background: #ffff00aa;
                    right: -14px;
                }

                &:first-of-type {
                    &::before {
                        display: none;
                    }
                }

                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        
        .function-item {
            width: 276px;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            row-gap: 20px;
            text-align: center;
            
            h3 {
                font-family: 'ToyotaType';
                @include font-size(20);
                font-weight: 600;
                line-height: 30px;
            }
            
            p {
                @include font-size(16);
                line-height: 23px;
                font-family: 'Nunito';
                font-weight: 400;
            }
        }
    }

    @include sm {
        margin-left: 10px;
        margin-right: 10px;
        &.dark-theme {
          margin-left: 0px;
          margin-right: 0px;
          padding-left: 10px;
          padding-right: 10px;
        }
        
        h1 {
            @include font-size(30);
            line-height: 35px;
        }
    }
}