.stations-map {
    background-color: $white;

    $root: &;

    &.pad-top {
        padding-top: 3.5rem;
    }

    .map {
        height: 35rem;
        background-color: $pale-blue;

        @include sm {
            height: 17.5rem;
        }

        [aria-label="Map"] {
            position: relative !important;
        }
    }

    &__header, &__all {
        min-height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include lg {
            display: block;
        }

        @include sm {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }


        &__title {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.25;
            margin-top: 0;
            margin-bottom: 0;
            color: $ocean;

            @include lg{
                width: 40%;
            }

            @include md {
                font-size: 1rem;
                width: 33%;
            }

            @include sm {
                width: 100%;
                font-size: 1.5rem;
            }
        }

        #{ $root }__location {
            @include lg {
                margin: 2rem 0 1rem;
            }

            @include sm {
                margin: 1rem 0 0;
            }

            #{ $root }__current-location {
                @include font-size(16);
            }
        }

    }

    &__all {
        @include md-min {
            height: 6.5rem;
        }
        @include sm {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        &__title {
            font-size: 1.5rem;
            @include md {
                width: 100%;
            }
            @include sm {
                margin-bottom: 1.5rem;
            }
        }
        .button--small {
            min-width: 9.75rem;
        }
    }

    &__location {
        @include sm {
            margin-top: 1.25rem;
        }
    }

    &__search-location {
        position: relative;
        display: inline-block;
        border-bottom: 1px solid $pale-blue;
        vertical-align: middle;

        &::before {
            content: "";
            top: 50%;
            left: 0;
            width: 17px;
            height: 17px;
            display: block;
            position: absolute;
            transform: translateY(-50%);
            background: url(../img/icons/search.svg) no-repeat center;
            background-size: contain;
        }

        @include sm {
            display: block;
            margin-top: 1.75rem;
            max-width: 20rem;
        }

        @include md-min {
            margin-left: 1rem;
        }

        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) and (max-width: 991px) {
            width: calc((768px - 2rem) / 3);
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) and (max-width: 1119px) {
            width: calc((992px - 2rem) / 3);
        }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
            width: calc((1100px - 2rem) / 3);
        }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1500px) {
            width: calc((1200px - 2rem) / 3);
        }
        
        .select2 {
            &-container {
                width: 100% !important;
                    .select2-selection {
                        &--single {
                            border: none !important;
                            padding-left: 1.5rem;
                            padding-right: 0;
                            background-color: transparent;
                            &:focus {
                                border: none;
                                outline: 0;
                                box-shadow: none;
                            }
                            .select2-selection {

                                &__placeholder, &__rendered {
                                    padding: 0;
                                    font-size: 14px;
                                    font-weight: normal;
                                    font-style: normal;
                                    font-stretch: normal;
                                    line-height: 1.71;
                                    letter-spacing: normal;
                                    color: #191919;
                                }
                                &__rendered {
                                    display: flex;

                                    &:focus {
                                        border: none;
                                        outline: 0;
                                        box-shadow: none;
                                    }

                                    span:last-child {
                                        width: 90%;
                                        overflow: hidden;
                                    }

                                }
                            }
                            .select2-selection__clear {
                                right: 0;
                                height: 90%;
                                width: 40px;
                                font-size: 0;
                                display: flex;
                                align-items: center;
                                position: absolute;
                                justify-content: flex-end;
                                background: rgba(255,255,255,0);
                                background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
                                background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(0%, rgba(255,255,255,0)), color-stop(59%, rgba(255,255,255,1)));
                                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
                                background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
                                background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
                                background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 59%);
                                &::before {
                                    content: "";
                                    width: 12px;
                                    height: 12px;
                                    display: block;
                                    background: url('../img/icons/clear.svg') no-repeat center;
                                    background-size: contain;
                                }
                            }
                        }
                    }

            }
        }

        &__label {
            line-height: 0;
            position: absolute;
            left: .6rem;
            top: 50%;
            transform: translateY(-50%);

            img {
                max-width: 100%;
            }
        }

        &__input {
            display: block;
            width: 100%;
            height: 1.75rem;
            border: none;
            outline: none;
            padding-left: 2rem;
            padding-right: .5rem;
        }
    }

    &__map-wrapper {
        position: relative;

        .container {
            pointer-events: none;

            @include lg-min {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .map__zoom {
            position: absolute;
            left: 1.75rem;
            top: 50%;
            transform: translateY(-50%);

            @include md {
                left: 1rem;
                top: 1rem;
                transform: none;
            }
        }
    }

    .stations-map__search-location {

        .select2-container--default
        .select2-selection--single
        .select2-selection__arrow {
            display: none;
        }
    }

    .select2-search--dropdown {
        display: block !important;
        padding: 0 !important;
        .select2-search__field {
            display: block;
            height: 30px;
            border-radius: 100px;
            padding: 0 .5rem 0 0 !important;
            margin-top: -31px;
            border: none !important;
            margin-bottom: 10px;
            margin-left: 1.5rem;
            width: 86%;
            background: white;
            -webkit-appearance: none;
            &::-webkit-textfield-decoration-container {
                background: white;
            }


            &:focus {
                outline: 0;
            }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                background-color: white;
                font-family: "ToyotaType", sans-serif;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                background-color: white;
                font-family: "ToyotaType", sans-serif;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                background-color: white;
                font-family: "ToyotaType", sans-serif;
            }
            &:-moz-placeholder { /* Firefox 18- */
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.71;
                letter-spacing: normal;
                background-color: white;
                font-family: "ToyotaType", sans-serif;
            }
            #editing-view-port {
                background: white;
            }
        }
    }
    .select2-dropdown {
        background-color: transparent !important;
        border: none !important;
        z-index: 2 !important;
    }

    .select2-results {
        background: white;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        border: 1px solid #d8d8d8;
        border-radius: 10px;
        color: $navy;
        margin-top: 10px;
        overflow-y: hidden;
        
        &__options {
            max-height: 300px !important;
            padding: 0 !important;
        }
        &__option {
            padding: 6px 15px !important;
            line-height: 1.4;
            font-size: 13px;
            strong {
                font-size: 14px;
                display: block;
            }
            &--highlighted, &[aria-selected=true] {
                color: $navy !important;
                background-color: #f4f4f4 !important;
            }
        }
    }

    .services_container {
        .select2-results__options {
            box-shadow: 2px 5px 20px 0px #00273226;
        }
    }

}


.map {
    &__zoom {
        box-shadow: 0 35px 53px 0 rgba($navy, 0.06);
        background-color: $white;
        border-radius: 6px;
        overflow: hidden;

        .hr {
            margin-left: .5rem;
            margin-right: .5rem;
        }
    }

    &__zoom-item {
        cursor: pointer;
        outline: none;
        height: 2rem;
        width: 2rem;
        background: transparent;
        border: none;

        display: flex;
        align-items: center;
        justify-content: center;

        @include md {
            height: 2.5rem;
            width: 2.5rem;
        }

        &__icon {
            font-size: 2rem;
            line-height: 0;

            @include md {
                font-size: 2rem;
            }
        }

        &--in {
            .map__zoom-item__icon {
                margin-top: .25rem;

                @include md {
                    margin-top: .25rem;
                }
            }
        }

        &--out {
            .map__zoom-item__icon {
                margin-top: -.25rem;

                @include md {
                    margin-top: -.25rem;
                }
            }
        }
    }

    &__info {
        position: relative;
        display: none;
        pointer-events: all;
        box-sizing: border-box;
        border-radius: 6px;
        backdrop-filter: blur(4px);
        background-color: $white;
        padding: 1.75rem;

        @include lg-min {
            width: (100% / 3);
            box-shadow: 0 35px 53px 0 rgba($navy, 0.06);
        }

        @include md {
            padding-left: 0;
            padding-right: 0;
        }

        &__close-button {
            position: absolute;
            cursor: pointer;
            top: .6rem;
            right: .6rem;
            height: 1rem;
            width: 1rem;
            padding: 0;
            border: 0;
            background: none;

            &:before,
            &:after {
                content: '';
                display: block;
                height: 1px;
                width: 1rem;
                background-color: $pale-blue;
                top: 50%;
                left: 50%;
            }

            &:before {
                transform: rotate(45deg)
            }

            &:after {
                transform: rotate(-45deg);
                margin-top: -1px;

            }
        }

        &__block {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            border-bottom: 1px solid $pale-blue;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            .title {
                font-weight: 600;
                font-size: .7rem;
                line-height: 1.8;
                margin-top: 0;
                margin-bottom: .25rem;
            }

            .text {
                margin-top: 0;
                margin-bottom: 0;
                font-weight: 500;
                font-size: .8rem;
                line-height: 1.8;
            }

            .link-icon {
                @include sm {
                    box-sizing: border-box;
                    display: inline-block;
                    text-decoration: none;
                    font-size: .65rem;
                    font-weight: 600;
                    line-height: 1.2rem;
                    text-align: center;
                    padding-top: .25rem;
                    padding-bottom: .25rem;
                    padding-left: .5rem;
                    padding-right: .5rem;
                    border-radius: 100px;
                    border: 2px solid;

                    min-width: 10rem;
                    text-decoration: none;
                    margin-bottom: 1rem;
                }

                &:last-child {
                    @include sm {
                        margin-bottom: 0;
                    }
                }

                &:hover,
                &:focus,
                &:active {
                    @include sm {
                        color: $white;
                        background-color: $navy;
                        border-color: $navy;
                    }

                    .link-icon__icon {
                        @include sm {
                            filter: invert(100%);
                        }
                    }
                }

                &__text{
                    @include sm {
                        text-decoration: none;
                    }
                }
            }
        }

        .links-list {
            margin: 0;
            margin-top: .75rem;
            padding: 0;
            list-style: none;

            &__item {
                @include sm {
                    text-align: center;
                    margin-bottom: 1rem;
                }

                &:last-child {
                    @include sm {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .phones {
            margin: 0;
            padding: 0;
            list-style: none;

            @media (min-width: $screen-sm-min) {
                display: flex;
                flex-wrap: wrap;
            }

            &__item {
                line-height: 1;

                @media (min-width: $screen-sm-min) {
                    width: 50%;
                    flex: 0 0 auto;
                }

                @include sm {
                    text-align: center;
                    margin-bottom: 1rem;
                }

                &:last-child {
                    @include sm {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__title {
            text-decoration: none;
            color: inherit;

            &__text {
                display: inline-block;
                margin-top: 0;
                margin-bottom: 0;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.25;
                vertical-align: middle;
            }

            &:before {
                content: url('../img/icons/www.png');
                display: inline-block;
                line-height: 0;
                vertical-align: middle;
                margin-top: .125rem;
            }
        }

        .services {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: .75rem;
            list-style: none;

            &__item {
                position: relative;
                margin-bottom: .25rem;
                font-weight: 500;
                font-size: .8rem;
                color: #9b9b9b;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:before {
                    content: '-';
                    position: absolute;
                    left: -.75rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.services_container {
    width: 100%;

    @include lg-min {
        max-width: 455px;
    }

    #kinto_station_services + span {
        width: 100% !important;
        // margin-top: 40px;

        @include lg-min {
            margin-top: 0;
        }

        .select2-selection.select2-selection--single {
            border: none;
            border-bottom: 1px solid #EBECED;

        }

        .select2-selection__rendered {
            font-family: 'ToyotaType';
            font-size: 16px;
            line-height: 27px;
            font-weight: 600;
            color: #0A2C3A;
        }
    }

    .select2-selection__placeholder {
        color: #0a2c3a !important;
    }
}

#select2-kinto_station_services-results {
    box-shadow: 2px 5px 20px rgba(0, 39, 50, 0.15);
    border-radius: 0px 0px 6px 6px;

    li {
        font-family: 'ToyotaType';
        font-size: 16px;
        line-height: 27px;
        font-weight: 600;
        color: #0a2c3a;
        padding: 19px 24px;
        border-bottom: 1px solid #ebf4f6;

        &.select2-results__option--highlighted {
            background: #ebf4f6;
        }
    }
}
