html {
    font-size: 20px;
    -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
            box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
            box-sizing: inherit;
}

body {
    margin: 0;
    min-width: 320px;
    font-family: "ToyotaType", sans-serif;
    line-height: 1.35;
    font-weight: 500;
    color: $navy;
    overflow: initial;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .site-wrapper {
        flex: 1 0 auto;
        overflow-x: hidden;
    }

    &.modal-opened {
        overflow: hidden;
    }
}

strong {
    font-weight: 600;
}

a {
    text-decoration: none;
    color: $ocean;
    transition: color .3s ease;

    &:hover {
        color: $navy;
        text-decoration: underline;
    }
}

.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.separator {
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    border-top: 1px solid $light-grey;

    &--top {
        margin-top: 2rem;
    }
}

//////////////////////
// SLIDERS
.slick-slider {
    &:hover {
        .slick-arrow {
            visibility: visible;
            opacity: 1;
        }
    }

    .slick-arrow {
        font-size: 0;
        padding: 0;
        border: none;
        background: none;
        width: 1rem;
        height: 1rem;
        outline: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        z-index: 1;
        height: 100%;
        width: 2rem;
        background-color: rgba($white, .75);
        transition: visibility 0s, opacity 0.5s linear;

        &:after {
            content: '';
            display: block;
            max-height: 1rem;
            max-width: 1rem;
            height: 100%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            background-image: url('../img/icons/chevron.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        @include md-min {
            visibility: hidden;
            opacity: 0;
        }

        @include sm {
            display: none !important;
        }

        &.slick-prev {
            left: 0rem;

            &:after {
                transform: rotate(90deg);
            }
        }

        &.slick-next {
            right: 0rem;

            &:after {
                transform: rotate(-90deg);
            }
        }

        &.slick-disabled {
            display: none !important;
        }
    }
}

.slick-dots {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    // @include sm {
    //     display: none !important;
    // }

    [role="presentation"] {
        line-height: 0;
        margin-left: .5rem;
        margin-right: .5rem;

        &:first-of-type {
            margin-left: 0;
        }
        
        &:last-of-type {
            margin-right: 0;
        }

        &.slick-active {
            [role="tab"] {
                width: 2rem;
                background-color: darken($pale-blue, 20%);
            }
        }
    }

    [role="tab"] {
        cursor: pointer;
        font-size: 0;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 100px;
        background-color: $pale-blue;
        height: .5rem;
        width: .5rem;
        outline: none;
        transition: all .4s ease-in-out;
    }
}