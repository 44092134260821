.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    b{
        font-weight: 400;
    }
    @at-root #{&}__item {
        overflow: hidden;
    
        @at-root #{&}__button {
            min-height: 5rem;
            width: 100%;
            cursor: pointer;
            padding: 0;
            border: none;
            border-bottom: 1px solid $steel-grey;
            background: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            color: inherit;
            text-decoration: none;
            outline: none;

            @include md {
                padding-top: .5rem;
                padding-bottom: .5rem;
            }
    
            @at-root #{&}__text {
                width: 100%;
                font-weight: 500;
                font-size: 1rem;
                line-height: 2.4;
                letter-spacing: -.29px;
                font-family: 'ToyotaType';

                @include md {
                    font-size: .9rem;
                    line-height: 2;
                }
            }
    
            @at-root #{&}__icon {
                flex: 0 0 auto;
                height: calc(2.5rem + 2px);
                width: calc(2.5rem + 2px);
                margin-top: -1px;
                margin-bottom: -1px;

                .icon {
                    position: relative;
                    height: 100%;
                    width: 100%;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        @include font-size(30);
                        font-weight: 300;
                        transition: opacity .4s ease-in-out;
                        color: $steel-grey;
                    }

                    &:before {
                        content: '+';
                        opacity: 1;
                    }

                    &:after {
                        content: '';
                        opacity: 0;
                        height: 0;
                        width: 17px;
                        border-top: 1px solid;
                        margin-top: 4px;
                    }
                }
            }
        }
    
        @at-root #{&}__body-wrapper {
            overflow: hidden;
            height: 0;
            border-bottom: 1px solid transparent;
            transition: all .4s ease-in-out;
        }
    
        @at-root #{&}__body {
            box-sizing: border-box;
            padding: 1rem;

            &__container {
                box-sizing: border-box;
                padding-top: .75rem;
                padding-bottom: .75rem;
                padding-left: 1rem;
                padding-right: 1rem;
                background-color: $white;
                
                * {
                    color: inherit;
                    font-size: .9rem;
                }

                p {
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                @include sm {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        &.-active {
            
            .accordion__item__body-wrapper {
                border-bottom-color: $steel-grey;
            }

            .accordion__item__button__icon {
                .icon {
                    &:before {
                        opacity: 0;
                    }
    
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &--open {
        .accordion__item__body-wrapper {
            height: auto;
        }
    }
}

.accordion-wrapper {
    @at-root #{&}__title {
    }
    .title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        h3{
            margin-bottom: 0px;
        }
    }
    .accordion {
        @at-root #{&}__item {

            @at-root #{&}__button {

                @at-root #{&}__text {
                }
            }

            @at-root #{&}__body {
                
            }

            &.-active {
                
            }
    
            &:hover,
            &:active,
            &:focus,
            &.-active {

            }
        }
    }
}

.accordion-container {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {

        &__title {
            font-weight: 600;
            font-size: .7rem;
            line-height: 1.8;
            margin-top: 0;
            margin-bottom: 0;
            border-bottom: solid 2px $pale-blue;
            padding-top: .75rem;
            padding-bottom: .75rem;
        }

        .accordion{
            &__item{
                &__button {
                    height: 2rem;

                    &__text {
                        font-size: .7rem;
                        line-height: 1.75;
                        line-height: 1;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    &__icon {
                        height: 2rem;
                        width: 2rem;

                        .icon {
                            &:before,
                            &:after {
                                font-size: 24px;
                                line-height: 0;
                            }

                            &:before {
                                margin-top: -.125rem;
                            }

                            &:after {
                                margin-top: 0;
                                width: 11px;
                            }
                        }
                    }
                }
                
                &__body{
                    &__container {
                        padding: 0;
                    }
                }
            }
        }
    }
}