//Colors
$white: #fff;
$navy: #002732;
$dark-blue: #1a394e;
$ocean: #00708d;
$aqua-blue: #00dbeb;
$light-aqua: #a4f9ff;
$deep-blue: #00495c;
$pale-blue: #e3e9eb;
$pale-grey: #ebf4f6;
$pale-light-grey: #e0e5e7;
$light-grey: #d4d7d9;
$steel-grey: #738184;
$cool-grey: #a6acb0;
$dark-cool-grey: #a4b6bb;
$orange: #eb4820;
//Gradients
$gradient-aqua: linear-gradient(45deg, #00dbeb 0%, #FFF 80%);

// Small tablets and large smartphones (landscape view)
$screen-sm: 577px;
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md: 769px;
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg: 993px;
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl: 1200px;
$screen-xl-min: 1199px;
