.sect_legal{
    padding-bottom: 180px;
    .legal{
        padding-bottom: 30px;
        padding-top: 40px;

        &__title {
            margin-bottom: 25px;
        }
    }
    b {
        font-weight: bold;
    }
    ul {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
    li {
        list-style: initial !important;
    }
}