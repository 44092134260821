.button {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    font-size: .65rem;
    font-weight: 600;
    line-height: 1.2rem;
    text-align: center;
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 100px;
    border: 2px solid;
    // background-color: $white;
    // color: $navy;
    outline: none;
    transition: all .4s ease-in-out;

    &:hover {
        text-decoration: none;
    }

    @include sm {
        padding-left: .5rem;
        padding-right: .5rem;
    }

    &--small {
        min-width: 7.5rem;
    }

    &--big {
        min-width: 10rem;
    }

    &--white {
        color: $navy;
        background-color: $white;
        border-color: $white;

        &:hover {
            color: $white;
            background-color: transparent;
        }

        &.button--outline {
            color: $white;
            background-color: transparent;

            &:hover {
                color: $navy;
                background-color: $white;
                border-color: $white;
            }
        }
    }

    &--dark {
        color: $white;
        border-color: $navy;
        background-color: $navy;

        &:hover {
            color: $navy;
            background-color: $pale-blue;
            border-color: $pale-blue;
        }

        &.button--outline {
            color: $navy;
            background-color: transparent;

            &:hover {
                color: $navy;
                background-color: $pale-blue;
                border-color: $pale-blue;
            }
        }
    }

    &--navy {
        border: 0;
        color: $white;
        background: $navy; /* Old browsers */
        background: -moz-linear-gradient(left, $deep-blue 0%, $navy 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $deep-blue 0%,$navy 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $deep-blue 0%,$navy 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        &:hover {
            color: $pale-blue;
        }
    }

    &--grey {
        color: $navy;
        background: linear-gradient(206.53deg, #EBF4F6 0.17%, #E3E9EB 79.79%);
        border: 0;
    }

    &--inactive {
        pointer-events: none;
        opacity: .5;
    }
}

.button-arrow {
    display: block;
    color: $navy;
    @include font-size(16);

    &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        margin-left: .5rem;
        background: no-repeat url('../img/icons/arrow-left-navy.svg') center center;
        background-size: contain;
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        text-decoration: none;

        &:after {
            transform: translate(10px);
        }
    }
}

.link-arrow {
    display: block;
    color: $navy;
    @include font-size(16);

    &:after {
        content: '';
        display: inline-block;
        width: 18px;
        height: 14px;
        margin-left: .5rem;
        background: no-repeat url('../img/icons/chevron-right.svg') center center;
        background-size: contain;
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        text-decoration: none;

        &:after {
            transform: translate(10px);
        }
    }
}

.button--store {
    display: inline-block;
    padding: 20px 30px;
    border-radius: 6px;
    background-color: $white;

    .button--store__svg {
        max-width: 150px;
        width: 100%;
    }
}