.sect_ayuda {
    b {
        font-weight: 600;
    }
    .questions-container{
        display: none;
        &.show{
            display: block;
        }
    }
    .header-title{
        margin-bottom: 60px;
    }
    .contact {
        &-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-gap: 0;
            grid-template-areas:    "title form"
                                    "info form";

            &__title {
                grid-area: title;
                padding-right: 4rem;
            }
            &__info {
                grid-area: info;
                padding-right: 4rem;

                &-schedule {
                    @include font-size(14);
                    line-height: 1.71;
                    margin-bottom: 1.5rem;

                    h5 {
                        @include font-size(20);
                        font-weight: 600;
                        margin-bottom: 1rem;
                        line-height: 1.27;
                    }
                    p {
                        margin: 1em 0;
                    }
                }

                &-networks {
                    &__title {
                        margin-bottom: 1.5rem;
                    }

                    &__list {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        @include flexbox(row, nowrap, start, center);

                        &-item {
                            display: inline-block;
                            margin-right: 1.5rem;

                            &:last-child {
                                margin-right: 0;
                            }

                            &__icon {
                                .inner {
                                    fill: $white;
                                    transition: fill .2s ease;
                                }
                                .outer {
                                    fill: $ocean;
                                    transition: fill .2s ease;
                                }

                                &:hover {
                                    .inner {
                                        fill: $ocean;
                                    }
                                    .outer {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__form {
                grid-area: form;
                border-left: 1px solid $navy;
                padding-left: 4rem;
            }
        }
    }

    @include md {
        .contact {
            &-row {
                grid-template-areas:    "title title"
                                        "form form"
                                        "info info";

                &__form {
                    border-left: 0;
                    padding-left: 0;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    @include sm {
        .accordion-wrapper {
            .title3 {
                margin-bottom: 1rem;
            }
        }

        .contact {
            &-row {
                &__title {
                    .title3 {
                        margin-bottom: 1rem;
                    }
                }

                &__info {
                    &-schedule {
                        display: none;
                    }
                }
            }
        }
    }
}