/* MIXINS: @include transition(); */
@mixin transition($prop: all, $time: 0.3s, $easing: ease) {
  	-webkit-transition: $prop $time $easing;
	-moz-transition: $prop $time $easing;
	-o-transition: $prop $time $easing;
	transition: $prop $time $easing;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

@mixin flexbox($dir: row, $wrap: wrap, $just: start, $align: start) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	
	//Direction
	-ms-flex-direction: $dir;
    -webkit-flex-direction: $dir;
    flex-direction: $dir;

    //Wrap 
    @if $wrap == nowrap {
    	-ms-flex-wrap: none;
		flex-wrap: nowrap;
    } @else {
		-ms-flex-wrap: $wrap;
		flex-wrap: $wrap;
    }

    //Justify
    @if $just == start or $just == end {
		-ms-flex-pack: $just;
      	-webkit-justify-content: flex-#{$just};
    	justify-content: flex-#{$just};
	} @else {
	    -ms-flex-pack: $just;
	    -webkit-justify-content: $just;
	    justify-content: $just;
	}
	
	//Align
	@if $align == start or $align == end {
		-ms-flex-align: $align;
      	-webkit-align-items: flex-#{$align};
    	align-items: flex-#{$align};
	} @else {
		-ms-flex-align: $align;
      	-webkit-align-items: $align;
    	align-items: $align;
	}
}

@mixin center-block($max-width: none) {
	display: block;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
}


//BREAKPOINTS
// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}
@mixin sm-min {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md}) {
        @content;
    }
}
@mixin md-min {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg}) {
        @content;
    }
}
@mixin lg-min {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl}) {
        @content;
    }
}
@mixin xl-min {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}
@mixin rwd-min($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}


//ANIMATIONS
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}  
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}  
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@mixin font-size($sizeValue: 14) {
    font-size: $sizeValue + px;
    font-size: ($sizeValue / 20) + rem;
}

@mixin helvetica() {
    font-family: 'Helvetica', sans-serif;
}