.sect_acerca {
    .hero-full {
        .title1 {
            max-width: 960px;
        }
        b {
            font-weight: 600;
        }
    }

    .intro {
        padding-bottom: 3rem;
        background: linear-gradient(135.07deg, #FFFFFF 0.13%, #EBF4F6 71.91%, #E3E9EB 100%);
        max-width: unset;
        margin: 0;
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .subhead {
        @include font-size(26);
        font-family: 'ToyotaType';
        font-weight: 600;
        line-height: 1.4;
        display: block;
        max-width: 400px;
        margin: 0 auto 3rem;
    }

    .teaser {
        @include font-size(18);
        line-height: 28px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        b{
            font-weight: 600
        }
    }

    .row {
        display: grid;
        grid-template-columns: 490px 1fr;
        grid-gap: 2rem;
    }

    .product-tree {
        width: 100%;
        margin-bottom: 3rem;
    }

    @include md {
        .subhead {
            margin-bottom: 0rem;
        }
        .row {
            grid-template-columns: 1fr;
        }
    }

    @include md {
        .teaser {
            @include font-size(20);
        }
    }

    @include sm{
        .intro{
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .teaser{
            p{
                @include font-size(18);
            }
        }
    }
}